import React, { useMemo } from "react";
import styled from "styled-components";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // snow theme

// import "./customQuillStyles.css";

// Custom colors array
export const customColors = [
  "#666666", // Your default text color
  "#222222", // heading color
  "#FF7A5C", // Your brand color
  "#F6E58D", // Light yellow
  "#30336B", // Dark blue
  "#FF6347", // Tomato red
  "#7BED9F", // Light green
];

// TODO: copy paste feature blog how to handle directly on the editor - https://billauer.co.il/blog/2021/12/quill-mixed-paste-format/
//  https://www.tiny.cloud/blog/under-pressure-powerpaste/
const RichTextEditor = ({
  theme = "snow",
  value,
  onChange,
  placeholder = "Start typing...",
  scrollingContainer = "html",
  modules,
  readOnly = false,
}) => {
  // Default modules if not provided
  const defaultModules = useMemo(
    () => ({
      toolbar: {
        container: [
          //   [{ font: [] }],
          //   [{ header: [1, 2, 3, 4] }],
          //   [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: customColors }, { background: [] }],
          [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          //   [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
        handlers: {
          image: "",
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <Wrapper>
      <ReactQuill
        theme={theme}
        placeholder={placeholder}
        modules={modules || defaultModules}
        value={value}
        onChange={onChange}
        scrollingContainer={scrollingContainer}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

export default RichTextEditor;
const Wrapper = styled.div`
  .ql-editor {
    background: #fff;
    /* background: transparent !important; */
    /* background-color: transparent; */
    /* color: #666; */
  }
  .ql-editor em {
    color: inherit !important;
    font-style: italic;
  }
`;
