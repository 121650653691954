import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentPopup = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCookieConsent(true);
    }, 10000);
  }, []);

  return (
    <>
      {/* TODO: load this component after 3s of main-page loading as it improves Cumulative layout shift */}
      {showCookieConsent && (
        <CookieConsent debug={false} buttonText="Got it" expires={30}>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      )}

      {/* <CookieConsent
        location="bottom"
        buttonText="I Accept"
        cookieName="myAppCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#4CAF50",
          color: "#ffffff",
          fontSize: "13px",
          borderRadius: "5px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. By continuing
        to browse, you agree to our use of cookies.
      </CookieConsent> */}
    </>
  );
};

export default CookieConsentPopup;
