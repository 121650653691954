import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";

import Loading from "../../components/Loading";
import RichTextEditor from "./components/RichTextEditor";
import {
  mandatoryField,
  themes,
  placeTypes,
  categories,
  getPlainTextFromRawHTML,
  cleanHtml,
} from "../../utils/utility";
import useApi from "../../utils/custom-hooks/useApi";

const allSubDestinatonTemp = [
  {
    id: 1,
    name: "Talsari beach",
  },
  {
    id: 2,
    name: "Renova",
  },
  {
    id: 3,
    name: "Indiana",
  },
  {
    id: 4,
    name: "Keloti",
  },
  {
    id: 5,
    name: "Lilawa",
  },
];

function NeighbourDestinationRichTextCRUD() {
  const { operationType, placeType } = useParams();

  const [placeId, setPlaceId] = useState("");
  const [mainDestinationId, setMainDestinationId] = useState("");

  const [makePublish, setMakePublish] = useState(false);
  const [placeOverview, setPlaceOverview] = useState("");

  const [subDestinationList, setSubDestinationList] =
    useState(allSubDestinatonTemp);

  const [redirect, setRedirect] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const editorModules = useMemo(
    () => ({
      toolbar: {
        container: [
          //   [{ font: [] }],
          [{ header: [1, 2, 3, 4] }],
          [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          //   [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
        handlers: {
          image: "",
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (operationType === "add") {
      const item = params.get("mainDestinationId");
      setMainDestinationId(item);
    } else {
      const item1 = params.get("mainDestinationId");
      const item2 = params.get("placeId");
      setMainDestinationId(item1);
      setPlaceId(item2);

      //TODO: placeId not get updated. that's why id is passed local one. Make it instantaneous
      fetchNeighbour(item2);
    }
  }, []);

  const createDummyNeighbourDestination = () => {
    //pre-filling fields for testing
    setValue("placeName", "Yindi");
    setPlaceOverview("about 2 km away from the airport..");
    setValue("streetAddress", "MB 42 a37 street kualalampur");
    setValue("latLong", "136.78, 439.54");
    setValue("country", "India");
    setValue("state", "JKD");
    setValue("placeType", "neighbour");

    // setValue("placeOverview", "about 2 km away from the airport..");
  };

  const fetchNeighbour = async (id) => {
    await makeApiCall("GET", `/bluff-destination/${id}`);
    if (resultRef.current && resultRef.current.status) {
      const destination = resultRef.current.destination;
      setValue("placeName", destination.place_name);
      setPlaceOverview(destination.place_info);
      setValue("placeType", "neighbour");
      setValue("streetAddress", destination.address.street_address);
      setValue("latLong", destination.address.lat_long);
      setValue("country", destination.address.country);
      setValue("state", destination.address.state);
      setMakePublish(destination.is_published);
      // setMainDestinationId(destination.main_destination_id);
      //   setValue("placeOverview", destination.place_info);
    }
  };

  const onSubmit = async (data) => {
    console.log("SUBMIT BUTTON CLICKED");

    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      placeOverview.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (!isValidContent) {
      alert("Enter the place overview");
      return;
    }

    // Cleaning all raw HTML content before sending to backend
    const placeInfoShortDescription = getPlainTextFromRawHTML(placeOverview);
    const placeOverviewClean = cleanHtml(placeOverview);

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };

    if (operationType === "add") {
      // Add operation
      const createdDest = {
        place_name: data.placeName,
        place_info: placeOverviewClean,
        place_info_short_description: placeInfoShortDescription,
        place_type: "neighbour",
        address: address,
        main_destination_id: mainDestinationId,
        is_published: makePublish,
      };
      await makeApiCall("POST", `/admin/bluff-destination`, createdDest);
    } else {
      // Edit operation
      var checkedValues = [];
      var inputElements = document.getElementsByClassName("uk-checkbox");
      for (var i = 0; inputElements[i]; ++i) {
        if (inputElements[i].checked) {
          checkedValues.push(inputElements[i].value);
        }
      }

      const updatedDest = {
        place_id: placeId,
        place_name: data.placeName,
        place_info: placeOverviewClean,
        place_info_short_description: placeInfoShortDescription,
        place_type: "neighbour",
        address: address,
        is_published: makePublish,
      };
      await makeApiCall("PUT", `/admin/bluff-destination/edit`, updatedDest);
    }

    setTimeout(() => {
      if (resultRef.current) {
        if (resultRef.current.status) {
          alert(`SUCCESS:  ${resultRef.current.message}`);
          history.replace(
            `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
          );
          // setRedirect(true);
        } else {
          alert(`FAIL: ${resultRef.current.message}`);
        }
      }
    }, 1000);
  };

  const handleDeleteNeighbour = async () => {
    await makeApiCall("DELETE", `/admin/bluff-destination/${placeId}`);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const onInvalid = (errors) => {
    console.log("ERRORS CALLED");
    console.error(errors);
  };

  const createNeighbourForm = () => {
    return (
      <div>
        {/* onSubmit = {handleSubmit(onSubmit)} */}
        <form
          class="uk-form-stacked"
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              id="publish-checkbox"
              checked={makePublish}
              onClick={() => setMakePublish(!makePublish)}
            />
            {` make publish`}
          </label>

          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Neighbour name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <--  place info --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place info</label>
            <RichTextEditor
              placeholder="Neighbour infos..."
              value={placeOverview}
              onChange={setPlaceOverview}
              modules={editorModules}
            />
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place Type (FIXED - neighbour)</label>
            <select
              class="uk-select"
              id="form-stacked-select"
              disabled
              defaultValue={"neighbour"}
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>

          {/* <-- linking with FD --> */}
          <label class="uk-form-label">
            Link with final destination (fields):{" "}
            <p className="uk-text-danger uk-text-small">
              currently mock data is used for finaldestination list that is
              static data
            </p>
          </label>

          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            {subDestinationList.map(({ id, name }) => {
              return (
                <label key={id}>
                  <input class="uk-checkbox" type="checkbox" value={name} />
                  {name}
                </label>
              );
            })}
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                {...register("latLong")}
              />
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          {/* <span className="uk-text-danger">{errors.toString()}</span> */}
          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={testSubmit}
            type="submit"
          >
            submit
          </button>
        </form>
        {/* <--  Delete --> */}
        {operationType === "edit" && (
          <button
            className="uk-button uk-button-danger uk-margin-small-bottom"
            onClick={handleDeleteNeighbour}
          >
            delete
          </button>
        )}
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <div className="uk-flex uk-flex-between uk-margin-small-top">
        <h2>
          {operationType === "add" ? "Neighbour Form" : "Update Nighbour Info"}
        </h2>
        <div>
          {operationType === "add" && (
            <button
              className="uk-button uk-button-default uk-button-small"
              onClick={createDummyNeighbourDestination}
            >
              test with dummy data
            </button>
          )}
        </div>
      </div>

      {createNeighbourForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </Wrapper>
  );
}

export default NeighbourDestinationRichTextCRUD;

const Wrapper = styled.main`
  background: #f2f2f2;
  margin-top: 2rem;
`;
