import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  mandatoryField,
  themes,
  placeTypes,
  categories,
  getPlainTextFromRawHTML,
  cleanHtml,
} from "../../utils/utility";
import Loading from "../../components/Loading";
import useApi from "../../utils/custom-hooks/useApi";
import { finalDestinations } from "../../mockDb";
import RichTextEditor, { customColors } from "./components/RichTextEditor";

const temp = [
  "/admin/add-subdestination-rich-text/:mainDestinationId",
  "placeId",
  "/admin/:operationType/:placeType?placeId= & mainDestinationId= ",
  "/admin/:operationType/finaldestination?placeId= & mainDestinationId= ",
];

function FinalDestinationRichTextCRUD() {
  const { operationType, placeType } = useParams();
  //   const { mainDestinationId } = useParams();
  const [placeId, setPlaceId] = useState("");
  const [mainDestinationId, setMainDestinationId] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [placeOverview, setPlaceOverview] = useState("");
  const [makePublish, setMakePublish] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const editorModules = useMemo(
    () => ({
      toolbar: {
        container: [
          //   [{ font: [] }],
          [{ header: [1, 2, 3, 4] }],
          [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: customColors }, { background: [] }],
          [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          //   [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
        handlers: {
          image: "",
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // const item1 = params.get("mainDestinationId");
    // const item2 = params.get("placeId");
    // console.log("FinalDestinationRichTextCRUD Query Param: ", item1, item2);

    if (operationType === "add") {
      const item = params.get("mainDestinationId");
      setMainDestinationId(item);
    } else {
      const item1 = params.get("mainDestinationId");
      const item2 = params.get("placeId");
      setMainDestinationId(item1);
      setPlaceId(item2);

      //TODO: placeId not get updated. that's why id is passed local one. Make it instantaneous
      fetchFinalDestination(item2);
    }
  }, []);

  const createDummyFinalDestination = () => {
    //pre-filling fields for testing
    const places = finalDestinations[0];
    const dest = places[0];
    setValue("placeName", dest.place_name);
    setValue("placeUniqueness", dest.place_uniqueness);
    setPlaceOverview(dest.place_info);
    setValue("countryRadBtn", "yes");
    setValue("stateRadBtn", "no");
    setValue("mainDestinationRadBtn", "yes");
    setValue("categoryType", "Temple");
    setValue("streetAddress", "MB 42 a37 street kualalampur");
    setValue("latLong", "136.78, 439.54");
    setValue("country", "India");
    setValue("state", "JKD");
    setValue("placeType", "finaldestination");

    //unused - merged into 1 field as rich-text-editor content
    // setValue("placeOverview", dest.place_info);
    // setTravelTipList(dest.travel_tips);
    // setNotToMissList(dest.not_to_miss);
    // setValue("whatPlaceOffers", dest.what_place_offers); [deprecated]
  };

  const fetchFinalDestination = async (id) => {
    await makeApiCall("GET", `/final-destination/${id}`);
    if (resultRef.current && resultRef.current.status) {
      const destination = resultRef.current.destination;
      setValue("placeName", destination.place_name);
      setPlaceOverview(destination.place_info);
      setValue("placeUniqueness", destination.place_uniqueness);
      setValue(
        "countryRadBtn",
        destination.popular_in_country === true ? "yes" : "no"
      );
      setValue(
        "stateRadBtn",
        destination.popular_in_state === true ? "yes" : "no"
      );
      setValue(
        "mainDestinationRadBtn",
        destination.popular_in_main_destination === true ? "yes" : "no"
      );
      setValue("categoryType", destination.category_type);
      setValue("placeType", "finaldestination");
      setValue("streetAddress", destination.address.street_address);
      setValue("latLong", destination.address.lat_long);
      setValue("country", destination.address.country);
      setValue("state", destination.address.state);
      setMakePublish(destination.is_published);
      //   setMainDestinationId(destination.main_destination_id);

      //   setValue("placeOverview", destination.place_info);
      // setTravelTipList(destination.travel_tips);
      // setNotToMissList(destination.not_to_miss);
      // setValue("whatPlaceOffers", destination.what_place_offers);
    }
  };

  const onSubmit = async (data) => {
    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      placeOverview.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (!isValidContent) {
      alert("Enter the place overview");
      return;
    }

    const pCountry = data.countryRadBtn === "yes" ? true : false;
    const pState = data.stateRadBtn === "yes" ? true : false;
    const pMainDestination =
      data.mainDestinationRadBtn === "yes" ? true : false;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };

    // Cleaning all raw HTML content before sending to backend
    const placeInfoShortDescription = getPlainTextFromRawHTML(placeOverview);
    const placeOverviewClean = cleanHtml(placeOverview);

    if (operationType === "add") {
      // Add operation
      const createdDest = {
        place_name: data.placeName,
        place_uniqueness: data.placeUniqueness,
        place_info: placeOverviewClean,
        place_info_short_description: placeInfoShortDescription,
        popular_in_country: pCountry,
        popular_in_state: pState,
        popular_in_main_destination: pMainDestination,
        category_type: data.categoryType,
        place_type: "finaldestination",
        destination_type: "finaldestination",
        is_published: makePublish,
        address: address,
        main_destination_id: mainDestinationId,
        //
        // not_to_miss: notToMissList,
        // travel_tips: travelTipList,
        // what_place_offers: data.whatPlaceOffers,
      };
      await makeApiCall("POST", `/admin/final-destination`, createdDest);
    } else {
      // Edit operation
      const updatedDest = {
        place_id: placeId,
        place_name: data.placeName,
        place_uniqueness: data.placeUniqueness,
        place_info: placeOverviewClean,
        place_info_short_description: placeInfoShortDescription,

        popular_in_country: pCountry,
        popular_in_state: pState,
        popular_in_main_destination: pMainDestination,
        category_type: data.categoryType,
        place_type: "finaldestination",
        destination_type: "finaldestination",
        address: address,
        is_published: makePublish,

        // what_place_offers: data.whatPlaceOffers,
        // not_to_miss: notToMissList,
        // travel_tips: travelTipList,
      };
      await makeApiCall("PUT", `/admin/final-destination/edit`, updatedDest);
    }

    setTimeout(() => {
      if (resultRef.current) {
        if (resultRef.current.status) {
          alert(`SUCCESS:  ${resultRef.current.message}`);
          history.replace(
            `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
          );
          // setRedirect(true);
          // submittedFinalDestionation(resultRef.current.destination);
        } else {
          alert(`FAIL: ${resultRef.current.message}`);
        }
      }
    }, 1000);
  };

  const handleDeleteFinalDestination = async () => {
    await makeApiCall("DELETE", `/admin/final-destination/${placeId}`);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const createSubDestinationForm = () => {
    return (
      <div>
        <form
          class="uk-form-stacked"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              id="publish-checkbox"
              checked={makePublish}
              onClick={() => setMakePublish(!makePublish)}
            />
            {` make publish`}
          </label>
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place name..."
              // value={name}
              // onChange={(e) => setName(e.target.value)}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <-- Uniqueness --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Uniqueness</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place uniqueness with 3-4 words..."
              {...register("placeUniqueness", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.placeUniqueness?.message}
            </span>
          </div>

          {/* <-- Overview (Place Info) --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Overview ( place info in short ), Not to Miss, Travel Tips, Nearby
              Places : Mention each section name then info, Don't mention
              Overview Name/Title
            </label>
            {/* <p>{placeOverview}</p> */}
            <RichTextEditor
              placeholder="Overview, Not to Miss, Travel Tips, Nearby
              Places..."
              value={placeOverview}
              onChange={setPlaceOverview}
              modules={editorModules}
            />
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("countryRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("countryRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.countryRadBtn?.message}
            </span>
          </div>

          {/* <-- popular in state --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in state</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("stateRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("stateRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.stateRadBtn?.message}
            </span>
          </div>

          {/* <-- pupular in main destination --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in main destination</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.mainDestinationRadBtn?.message}
            </span>
          </div>

          {/* <-- Category type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Category Type</label>
            <select
              class="uk-select"
              id="form-stacked-select"
              {...register("categoryType", { required: true })}
            >
              {categories.map((category) => {
                return <option>{category}</option>;
              })}
            </select>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>

          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Place Type (FIXED - finaldestination)
            </label>
            {/* <select
              class="uk-select"
              id="form-stacked-select"
              disabled
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select> */}
          </div>

          {/* <-- destination type --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">
              Destination type (FIXED - finaldestination)
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" disabled />
              main destination
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" checked />
              final destination
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" disabled />
              none
            </label>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setAddress(e.target.value)}
              // value={address}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                {...register("latLong")}
              />
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={handleSubmit}
            type="submit"
          >
            {operationType === "add" ? "submit" : "update"}
          </button>
        </form>

        {/* <--  Delete --> */}
        {operationType === "edit" && (
          <button
            className="uk-button uk-button-danger uk-margin-small-bottom"
            onClick={handleDeleteFinalDestination}
          >
            delete
          </button>
        )}
      </div>
    );
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <div className="uk-flex uk-flex-between uk-margin-small-top">
        <h2>
          {operationType === "add"
            ? "Final Destination Form"
            : "Update Final Destination Info"}
        </h2>
        <div>
          {operationType === "add" && (
            <button
              className="uk-button uk-button-default uk-button-small"
              onClick={createDummyFinalDestination}
            >
              test with dummy data
            </button>
          )}
        </div>
      </div>

      {createSubDestinationForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #f2f2f2;
  margin-top: 2rem;
`;
export default FinalDestinationRichTextCRUD;
