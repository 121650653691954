import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Link, useLocation, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import { getUser } from "../utils/utility";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import { useAppContext } from "../contexts/appContext";

function BookmarkModal({ modalPlaceId, alreadyBookmarked, updateData }) {
  const user = getUser();
  const history = useHistory();
  const location = useLocation();

  const [bucketName, setBucketName] = useState("");
  const [bucketDescription, setBucketDescription] = useState("");
  const [bucketList, setBucketList] = useState([]);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const [showModal, setShowModal] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const { addInCache, getFromCache } = useAppContext();

  useEffect(() => {
    if (user) {
      // const savedData = sessionStorage.getItem("my-buckets");
      // const result = savedData ? JSON.parse(savedData) : null;

      const result = getFromCache("my-buckets");
      if (result) {
        setBucketList(result);
      } else {
        fetchBuckets();
      }
    }
  }, [modalPlaceId]);

  useEffect(() => {
    // let isExist = false;
    // bucketList.map((bucket) => {
    //   const item = bucket.places.find((place) => place == modalPlaceId);
    //   if (item) isExist = true;
    // });
    // const neddRefresh = alreadyBookmarked !== isExist;

    let isExist = false;
    var inputElements = document.getElementsByClassName("uk-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        isExist = true;
      }
      console.log("bucketList-HOOK", inputElements[i].checked);
    }
    const neddRefresh = alreadyBookmarked === isExist ? false : true;

    if (user && neddRefresh) {
      // toast(`Refresh require ${alreadyBookmarked} ${isExist}`);
      updateData(modalPlaceId, !alreadyBookmarked);
    }
  }, [bucketList]);

  const fetchBuckets = async () => {
    await makeApiCall("GET", `/buckets/${user.user_id}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setBucketList(resultRef.current.buckets);
        // sessionStorage.setItem(
        //   "my-buckets",
        //   JSON.stringify(resultRef.current.buckets)
        // );
        addInCache("my-buckets", resultRef.current.buckets);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const itemClick = () => {
    setShowForm(!showForm);
  };

  const handleLoginButtonClick = () => {
    // setShowForm(!showForm);
    history.push("/login", {
      from: location.pathname,
    });
  };

  const createBucketAndAddItemInIt = async () => {
    if (!bucketName) {
      toast.error("Please enter bucket name");
      return;
    }

    // if (!bucketDescription) {
    //   toast.error("Please enter bucket description");
    //   return;
    // }

    const data = {
      bucket_name: bucketName,
      bucket_description: bucketDescription,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/create-bucket-and-add-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        toast.success("Wohoo... successfully bucket created & added!");
        setBucketList(resultRef.current.buckets);
        setShowForm(false);
        setBucketName("");
        setBucketDescription("");
        // sessionStorage.setItem(
        //   "my-buckets",
        //   JSON.stringify(resultRef.current.buckets)
        // );
        addInCache("my-buckets", resultRef.current.buckets);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const addItemInBucket = async (bucketId) => {
    const data = {
      bucket_id: bucketId,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/add-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success("Wohoo... successfully added in bucket!");
        setBucketList(resultRef.current.buckets);
        // sessionStorage.setItem(
        //   "my-buckets",
        //   JSON.stringify(resultRef.current.buckets)
        // );
        addInCache("my-buckets", resultRef.current.buckets);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const removeItemFromBucket = async (bucketId) => {
    const data = {
      bucket_id: bucketId,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/remove-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success("Yup... successfully removed from bucket!");
        setBucketList(resultRef.current.buckets);
        // sessionStorage.setItem(
        //   "my-buckets",
        //   JSON.stringify(resultRef.current.buckets)
        // );
        addInCache("my-buckets", resultRef.current.buckets);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const checkboxEventListener = (e) => {
    if (e.target.checked) {
      addItemInBucket(e.target.value);
    } else {
      removeItemFromBucket(e.target.value);
    }
  };

  const nonSignedInSection = () => {
    return (
      <div className="uk-text-center uk-margin-medium-top">
        <p>Please login to add place in your bucket.</p>
        <button
          className="uk-button uk-button-secondary"
          onClick={handleLoginButtonClick}
        >
          Login
        </button>
      </div>
    );
  };

  const privateSection = () => {
    return (
      <>
        {isLoading && <Loading />}
        {error.show && (
          <div className="uk-alert-danger" data-uk-alert>
            <p className="uk-text-danger uk-margin-small-left">{error.msg}</p>
          </div>
        )}
        <div className="modal-header">
          <h4 className="uk-margin-remove-bottom">Select bucket to add</h4>
        </div>

        <div className="modal-body">
          <section>
            {bucketList && bucketList.length !== 0 ? (
              bucketList.map((bucket) => {
                const item = bucket.places.filter(
                  (place) => place == modalPlaceId
                );

                const isExist = item.length === 0 ? false : true;

                return (
                  <article
                    className="uk-flex uk-flex-row"
                    key={bucket.bucket_id}
                  >
                    <label className="item-1">
                      <input
                        className="uk-checkbox uk-margin-remove-top item-2"
                        type="checkbox"
                        value={bucket.bucket_id}
                        onChange={checkboxEventListener}
                        checked={isExist}
                      />
                      {bucket.bucket_name}
                    </label>

                    {/* <input
                    className="uk-checkbox uk-margin-remove-top"
                    type="checkbox"
                    value={bucket.bucket_id}
                    onChange={checkboxEventListener}
                    checked={isExist}
                  />

                  <h6 className="uk-margin-remove-top uk-margin-small-left bucket-name-text">
                    {bucket.bucket_name}
                  </h6> */}
                  </article>
                );
              })
            ) : (
              <p>No bucket found. Create a new bucket first!</p>
            )}
          </section>
        </div>

        <div className="modal-footer">
          {!showForm && (
            <h4
              className="new-bucket-text uk-margin-remove-bottom"
              onClick={itemClick}
            >
              Create new bucket
            </h4>
          )}
          {showForm && (
            <div>
              <div className="uk-margin">
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Name of your bucket"
                  value={bucketName}
                  onChange={(e) => setBucketName(e.target.value)}
                />
              </div>

              <div className="uk-margin">
                <textarea
                  className="uk-textarea"
                  rows="5"
                  placeholder="Description (optional)"
                  value={bucketDescription}
                  onChange={(e) => setBucketDescription(e.target.value)}
                ></textarea>
              </div>

              <button
                className="uk-button uk-button-secondary"
                onClick={createBucketAndAddItemInIt}
              >
                create
              </button>

              <button
                className="uk-button uk-button-default uk-margin-small-left"
                onClick={itemClick}
              >
                close
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Wrapper>
      {!user && nonSignedInSection()}
      {user && privateSection()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .remove-margin {
    margin-top: 0px;
  }
  .new-bucket-text {
    color: #338280;
  }
  .new-bucket-text:hover {
    color: #0ddfd8;
    cursor: pointer;
  }

  .modal-body {
    /* padding: 20px 20px; */
    padding: 20px 20px 2px 20px;
    /* border: 1px solid red; */
  }

  .modal-header {
    padding: 10px 20px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    /* border: 1px solid red; */
  }

  .modal-footer {
    padding: 10px 20px;
    background: #fff;
    border-top: 1px solid #e5e5e5;
    /* border: 1px solid red; */
  }
  @media (min-width: 640px) {
    .modal-body {
      padding: 30px 30px 10px 30px;
    }
    .modal-header {
      padding: 10px 30px;
    }
    .modal-footer {
      padding: 10px 30px;
    }
  }

  .bucket-name-text {
    // font-size: 14px;
    // border: 2px solid red;
    color: var(--color--text);
    font-weight: 300;
  }
  .item-2 {
    margin-right: 10px;
  }
  .item-1 {
    margin-bottom: 4px;
    font-size: 15px;
    // border: 2px solid red;
    // color: var(--color--text);
    font-weight: 300;
  }
`;

export default BookmarkModal;
