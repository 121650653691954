import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loading from "../../components/Loading";
import AddSubDestination from "./components/AddSubDestination";
import AddNeighbour from "./components/AddNeighbour";
import {
  MAINDESTINATION,
  FINALDESTINATION,
  NEIGHBOUR,
  NEIGHBOURDESTINATION,
} from "../../utils/constants";
import useApi from "../../utils/custom-hooks/useApi";
import { defaultImage } from "../../utils/utility";
import { useAppContext } from "../../contexts/appContext";

function DestinationListing() {
  const { placeId } = useParams();

  const [placeImage, setPlaceImage] = useState(defaultImage);
  const [name, setName] = useState("");
  const [overview, setOverview] = useState("");
  const [subDestinationList, setSubDestinationList] = useState([]);
  const [neighbourList, setNeighbourList] = useState([]);
  const [showSubDestinationForm, setShowSubDestinationForm] = useState(false);
  const [showNeighbourForm, setShowNeighbourForm] = useState(false);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const { addInCache, getFromCache } = useAppContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const data = getFromCache(`md-id-${placeId}`);
    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const dataUpdated = params.get("refreshRequired") === "true";

    // const state = location.state;
    // const dataUpdated = state && state.dataUpdated

    // Fetch data if it's not already present or if data was updated
    if (!data || dataUpdated) {
      fetchMainDestinationAllInfo();
      // Clear the query parameter to prevent re-fetching on subsequent renders
      if (dataUpdated) {
        // Clear the query parameter using history.replace
        const cleanUrl = location.pathname;
        history.replace(cleanUrl);
        // history.replace(`/admin/destination-listing/${placeId}`);
        //
        // Clear the state to prevent re-fetching on subsequent renders
        // history.replace({
        //   pathname: `/admin/destination-listing/${placeId}`,
        //   state: {},
        // });
      }
    } else {
      setAllData(data);
    }
  }, []);

  // get main-destination, sub-destination, neighbour info
  const fetchMainDestinationAllInfo = async () => {
    await makeApiCall("GET", `/main-destination-all-info/${placeId}`);
    if (resultRef.current && resultRef.current.status) {
      setAllData(resultRef.current);
      addInCache(`md-id-${placeId}`, resultRef.current);
      //
      // const destination = resultRef.current.destination;
      // setName(destination.place_name);
      // setOverview(destination.place_info);
      // setPlaceImage(
      //   destination.images[0] ? destination.images[0].image_url : defaultImage
      // );
      // setSubDestinationList(resultRef.current.finalDestinations);
      // setNeighbourList(resultRef.current.neighbours);
    } else {
      toast.error(`${resultRef.current.message}`);
    }
  };

  const setAllData = (response) => {
    const dest = response.destination;
    setName(dest.place_name);
    setOverview(dest.place_info_short_description);
    // setOverview(dest.place_info);
    setPlaceImage(dest.images[0] ? dest.images[0].image_url : defaultImage);
    setSubDestinationList(response.finalDestinations);
    setNeighbourList(response.neighbours);
  };

  const subDestinationClickHandler = () => {
    // neighbour form already open
    if (showNeighbourForm) {
      setShowSubDestinationForm(true);
      setShowNeighbourForm(false);
      return;
    }

    setShowSubDestinationForm(!showSubDestinationForm);
  };

  const neighbourClickHandler = () => {
    // sub-destination form already open
    if (showSubDestinationForm) {
      setShowNeighbourForm(true);
      setShowSubDestinationForm(false);
      return;
    }

    setShowNeighbourForm(!showNeighbourForm);
  };

  const mainDestinationBriefInfo = () => {
    return (
      <section className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover uk-flex uk-margin-medium-top">
        <img className="uk-width-1-3" src={placeImage} alt={name} />
        <div className="uk-width-2-3 uk-margin-small-left">
          <h3>{name}</h3>
          <p className="text-eql-aligned">{overview}</p>
          {/* <p>{overview && overview.slice(0, 80)}.....</p> */}
          <Link
            className="button uk-button uk-button-default uk-margin-small-right"
            to={`/admin/edit-destination-rich-text/${MAINDESTINATION}/${placeId}`}
          >
            edit RT
          </Link>
          {/* <Link
            className="button uk-button uk-button-default uk-margin-small-right"
            to={`/admin/edit-destination/${MAINDESTINATION}/${placeId}`}
          >
            edit PT
          </Link> */}
          <Link
            className="button uk-button uk-button-default"
            // to={`/admin/destination-images/${placeId}`}
            to={`/admin/image-listing/${"destination"}/${placeId}`}
          >
            images
          </Link>
        </div>
      </section>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const addedSubDestinationsAndNeighbours = () => {
    return (
      <>
        <h2>All Final-Destination</h2>
        {subDestinationList && subDestinationList.length === 0 && (
          <h4>nothing found</h4>
        )}
        <section
          className="uk-grid-match uk-grid-small uk-child-width-1-3"
          data-uk-grid
        >
          {subDestinationList &&
            subDestinationList.map((destination) => {
              return (
                <article
                  className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover"
                  key={destination.place_id}
                >
                  <img
                    src={
                      destination.images[0]
                        ? destination.images[0].image_url
                        : defaultImage
                    }
                    alt={destination.place_name}
                  />
                  <h4 className="uk-margin-small-top uk-margin-small-bottom">
                    {destination.place_name}
                  </h4>
                  <p className="line-limit text-eql-aligned uk-margin-remove-top">
                    {destination.place_info_short_description}
                    {/* {destination.place_info.slice(0, 55)}..... */}
                  </p>
                  <div>
                    <Link
                      className="button uk-button uk-button-default uk-margin-small-right"
                      to={`/admin/${"edit"}/${FINALDESTINATION}?mainDestinationId=${placeId}&placeId=${
                        destination.place_id
                      }`}
                      // to={`/admin/edit-destination/${FINALDESTINATION}/${destination.place_id}`}
                    >
                      edit
                    </Link>
                    <Link
                      className="button uk-button uk-button-default"
                      // to={`/admin/destination-images/${destination.place_id}`}
                      to={`/admin/image-listing/${"destination"}/${
                        destination.place_id
                      }`}
                    >
                      images
                    </Link>
                  </div>
                </article>
              );
            })}
        </section>
        <h2>All Neighbour</h2>
        {neighbourList && neighbourList.length === 0 && <h4>nothing found</h4>}

        <section
          className="uk-grid-match uk-grid-small  uk-child-width-1-3 uk-margin-medium-bottom"
          data-uk-grid
        >
          {neighbourList &&
            neighbourList.map((destination) => {
              return (
                <article
                  className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover"
                  key={destination.place_id}
                >
                  <img
                    src={
                      destination.images[0]
                        ? destination.images[0].image_url
                        : defaultImage
                    }
                    alt={destination.place_name}
                  />
                  <h4 className="uk-margin-small-top uk-margin-small-bottom">
                    {destination.place_name}
                  </h4>
                  <p className="line-limit text-eql-aligned uk-margin-remove-top">
                    {destination.place_info_short_description}
                    {/* {destination.place_info.slice(0, 55)}..... */}
                  </p>
                  <div>
                    <Link
                      className="button uk-button uk-button-default uk-margin-small-right"
                      to={`/admin/${"edit"}/${NEIGHBOURDESTINATION}?mainDestinationId=${placeId}&placeId=${
                        destination.place_id
                      }`}
                    >
                      edit RT
                    </Link>
                    {/* <Link
                      className="button uk-button uk-button-default uk-margin-small-right"
                      to={`/admin/edit-destination/${NEIGHBOUR}/${destination.place_id}`}
                    >
                      edit PT
                    </Link> */}
                    <Link
                      className="button uk-button uk-button-default"
                      // to={`/admin/destination-images/${destination.place_id}`}
                      to={`/admin/image-listing/${"destination"}/${
                        destination.place_id
                      }`}
                    >
                      images
                    </Link>
                  </div>
                </article>
              );
            })}
        </section>
      </>
    );
  };

  const buttonAndFormSegment = () => {
    return (
      <>
        <div className="uk-flex uk-child-width-1-2 uk-margin-medium-top uk-margin-medium-bottom">
          <div>
            <Link
              className="button uk-button uk-button-secondary"
              to={`/admin/${"add"}/${FINALDESTINATION}?mainDestinationId=${placeId}&placeId=`}
            >
              Add Final Destination RT
            </Link>

            {/* <Link
              className="button uk-button uk-button-secondary uk-margin-small-top"
              to={`/admin/add-subdestination/${placeId}`}
            >
              Add Final Destination PT
            </Link> */}
          </div>

          <div>
            <Link
              className="button uk-button uk-button-secondary"
              to={`/admin/${"add"}/${NEIGHBOURDESTINATION}?mainDestinationId=${placeId}&placeId=`}
            >
              Add Neighbour RT
            </Link>
            {/* <Link
              className="button uk-button uk-button-secondary"
              to={`/admin/add-neighbour/${placeId}`}
            >
              Add Neighbour PT
            </Link> */}
          </div>

          {/* <div>
            <button
              className="uk-button uk-button-secondary"
              onClick={subDestinationClickHandler}
            >
              {showSubDestinationForm ? "Close" : "Add Final-destination"}
            </button>
          </div>

          <div>
            <button
              className="uk-button uk-button-secondary"
              onClick={neighbourClickHandler}
            >
              {showNeighbourForm ? "Close" : "Add neighbour"}
            </button>
          </div> */}
        </div>

        {/* <div className={showSubDestinationForm ? "uk-visible" : "uk-hidden"}>
          <AddSubDestination mainDestinationId={placeId} />
        </div>
        <div className={showNeighbourForm ? "uk-visible" : "uk-hidden"}>
          <AddNeighbour mainDestinationId={placeId} />
        </div> */}
      </>
    );
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {showLoading()}
      {errorMessage()}
      {mainDestinationBriefInfo()}
      {buttonAndFormSegment()}
      {addedSubDestinationsAndNeighbours()}
    </Wrapper>
  );
}

export default DestinationListing;

const Wrapper = styled.main`
  background: #f2f2f2;
  .text-eql-aligned {
    text-align: justify;
  }
  .line-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    text-align: justify;

    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;
