import React, { useState, useEffect } from "react";
import styled from "styled-components";

const offlineStatusStye = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#fef4f6",
  color: "#f0506e",
  // backgroundColor: "red",
  // color: "white",
  textAlign: "center",
  padding: "10px",
  zIndex: "12",
};

const onlineStatusStye = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#edfbf6",
  color: "#32d296",
  // backgroundColor: "green",
  // color: "white",
  textAlign: "center",
  padding: "10px",
  zIndex: "12",
};

const InternetStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Update status when the user goes online or offline
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    // Event listeners for online and offline
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <div style={offlineStatusStye}>
          You are offline. Please check your internet connection.
        </div>
      )}
      {/* {isOnline && (
        <div style={onlineStatusStye}>
          You are online. Your internet connection is good.
        </div>
      )} */}
      {/* {isOnline && (
        <div className="uk-alert-danger" data-uk-alert>
          <p className="uk-text-danger">
            You are online. Your internet connection is good.
          </p>
        </div>
      )} */}
    </>
  );
};

const Wrapper = styled.div``;

export default InternetStatus;
