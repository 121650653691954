import imgNotFound from "../assets/resizecom_gradient_1_A.jpeg";
// import imgNotFound from "../assets/resizecom_gradient_1_B.jpeg";
// import imgNotFound from "../assets/resizecom_gradient_2_A.jpeg";
// import imgNotFound from "../assets/resizecom_gradient_2_B.jpeg";
// import imgNotFound from "../assets/resizecom_image_not_found_1.jpeg";

// import imgNotFound from "../assets/image_not_found_1.png";
import errorImg from "../assets/error.jpg";

import heartIc from "../assets/heart_new_1.gif";
import bucketIc from "../assets/bucket_list_new_1.gif";
import videoIc from "../assets/video_new_1.gif";
import travelGuideIc from "../assets/travel_guide_new_1.gif";

const cacheList = [];
export const categories = [
  "Travel Category",
  "Religious",
  "Historical",
  "Adventure",
  "Nature",
  "Cultural",
  "Modern Attractions",
  "Offbeat",
  "Food & Cuisine",
  "Shopping",
  "Sights & Attractions",
];
const mockCategories = [
  "Beachs",
  "Adventure",
  "Arts",
  "Desert",
  "Entertainment",
  "Experiences",
  "Food & Drink",
  "History & Heritage",
  "Sports",
  "Theme Park",
  "Museums",
  "Nature",
  "NightLife",
  "Shopping",
];
export const placeTypes = [
  "maindestination",
  "finaldestination",
  "country",
  "state",
  "neighbour",
];

export const themes = [
  "Travel Theme",
  "Cultural Heritage",
  "Adventure & Nature",
  "Beach Adventure",
  "Modern & Urban",
  "Historical & Archaeological",
  "Food & Flavor",
  "Luxury & Leisure",
  "Spiritual & Wellness",
  "None",
];

export const mandatoryField = {
  required: {
    value: true,
    message: "field is required",
  },
};

export const errorImage = errorImg;
export const imageNotFound = imgNotFound;
export const defaultImage = imgNotFound;
export const heartIcon = heartIc;
export const videoIcon = videoIc;
export const bucketIcon = bucketIc;
export const travelGuideIcon = travelGuideIc;

// "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80";

export const defaultUserImage = "";
export const defaultBucketImage = "";
export const contactEmail = "hi.travelbucket@gmail.com";

export const addUser = (user, token) => {
  // In JS, if data exist means truth & null, undefined means false.
  // So, In boolean return type, you can return either true, false as well data
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

export const removeUser = () => {
  localStorage.clear();
  // localStorage.removeItem("user");
  // localStorage.removeItem("token");
};

export const getUser = () => {
  // const token = localStorage.getItem("token");
  const data = localStorage.getItem("user");
  const user = data ? JSON.parse(data) : null;
  return user;
};

function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const addUserWithExpiry = (user, token) => {
  const LOCALSTORAGE_EXPIRY_TIME = 1 * 24 * 60 * 60 * 1000; //1 day expiry [in milliseconds]
  const now = new Date();
  // const currentTime = now.getTime();
  // const expireTime = now.getTime() + process.env.REACT_APP_LOCALSTORAGE_EXPIRY_TIME;
  const expireTime = now.getTime() + LOCALSTORAGE_EXPIRY_TIME;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
  localStorage.setItem("user-expiry", expireTime);

  // console.log(
  //   "EXPIRY TIME: ",
  //   expireTime,
  //   currentTime,
  //   LOCALSTORAGE_EXPIRY_TIME
  // );
};

export const checkExpiryOfUser = () => {
  const expireTime = localStorage.getItem("user-expiry");
  if (!expireTime) {
    return;
  }
  const now = new Date();
  if (now.getTime() > expireTime) {
    localStorage.clear();

    // localStorage.removeItem("user-expiry");
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    return;
  }
};

// MD listing (vertical): More text Characters - 220
// FD listing (grid in desktop)  3 lines then …
//
export const getPlainTextFromRawHTML = (rawHtml, characterLength = 220) => {
  const decodedBody = rawHtml.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  const strippedBody = decodedBody.replace(/<[^>]*>?/gm, "");
  // Extract plain text description up to 220 characters
  const plainText =
    strippedBody.slice(0, characterLength) +
    (strippedBody.length > characterLength ? "..." : "");
  return plainText;
};

export const cleanHtml = (rawHtml) => {
  //
  // TODO: move this code of removing unwanted tags from user side to directly on amdin side where it is uploaded to backend
  // REGEX1: Remove empty <p><br></p> or <p><br/></p> tags
  // const removedUnwantedHtml = rawHtml.replace(/<p><br\s*\/?><\/p>/g, "");

  // REGEX2: Remove empty <p><br/></p> tags, but keep others with content or intentional line breaks
  // const removedUnwantedHtml = rawHtml.replace(/<p><br\s*\/?><\/p>/g, "");

  // REGEX3: Replace multiple empty <p><br/></p> with a single blank space <p>&nbsp;</p>
  let cleanedHtml = rawHtml.replace(
    /(<p><br\s*\/?><\/p>\s*)+/g,
    "<p>&nbsp;</p>"
  );

  // Step 1: Remove all background-color styles
  cleanedHtml = cleanedHtml.replace(/background-color:\s*[^;]+;?/g, "");

  // Step 2: Replace existing color or add it if missing
  // cleanedHtml = cleanedHtml.replace(/style="([^"]*)"/g, (match, styles) => {
  //   // Check if color is already present
  //   if (/color:\s*[^;]+;?/.test(styles)) {
  //     // Replace the existing color
  //     return `style="${styles.replace(/color:\s*[^;]+;?/, "color: #666;")}"`;
  //   } else {
  //     // Append color to the style attribute
  //     return `style="${styles.trim()} color: #666;"`;
  //   }
  // });

  return cleanedHtml;
};
