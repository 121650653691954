import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import CompleteVideoCompoent from "../components/CompleteVideoComponent";
import { videoList } from "../mockDb";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import CompleteVideoComponentNew from "../components/CompleteVideoComponentNew";
import { toast } from "react-hot-toast";
import { useAppContext } from "../contexts/appContext";

const url = "http://localhost:5000/destinations-videos";

const mockData = [
  {
    title: "GDA6P9 dest",
    url: "https://www.youtube.com/embed/LNBQ-eYzjMk",
    image: "",
    language: "english",
    at_home_page: true,
    createdAt: "2024-12-27T15:45:42.902Z",
    updatedAt: "2024-12-30T11:08:17.645Z",
    images: [
      {
        image_url:
          "https://res.cloudinary.com/dtvwe5xxk/image/upload/v1735400244/videos/rc4qsaymqgwppp4v46ko.jpg",
        image_id: "videos/rc4qsaymqgwppp4v46ko",
        source_name: "internally",
        source_url: "pixel",
        _id: "67701b35a96c2d653db3f942",
      },
    ],
    video_id: "676ecba60c47f98abec4b095",
  },
  {
    title: "7 Interesting Facts about Kochi That Will Amaze You!",
    url: "https://www.youtube.com/embed/6d815EaJ4n8",
    language: "english",
    at_home_page: true,
    images: [
      {
        image_url:
          "https://res.cloudinary.com/dtvwe5xxk/image/upload/v1735409178/videos/ykrph92hhtwhguqn1azl.jpg",
        image_id: "videos/ykrph92hhtwhguqn1azl",
        source_name: "mnk",
        source_url: "ytr",
        _id: "67703e1ba96c2d653db3fa7c",
      },
    ],
    createdAt: "2024-12-28T17:29:16.230Z",
    updatedAt: "2024-12-30T11:09:46.120Z",
    video_id: "6770356ca96c2d653db3f9c6",
  },
  {
    title: "XTRY0856 DZR",
    url: "https://www.youtube.com/embed/tvbiybilGNY",
    language: "english",
    at_home_page: true,
    images: [
      {
        image_url:
          "https://res.cloudinary.com/dtvwe5xxk/image/upload/v1735407305/videos/yudgidguq0jimiveh8iz.jpg",
        image_id: "videos/yudgidguq0jimiveh8iz",
        source_name: "mnj",
        source_url: "rty",
        _id: "677036caa96c2d653db3f9f3",
      },
    ],
    createdAt: "2024-12-28T17:29:52.669Z",
    updatedAt: "2024-12-30T11:10:39.982Z",
    video_id: "67703590a96c2d653db3f9d0",
  },
  {
    title: "Puri A8MT74 place",
    url: "https://www.youtube.com/embed/P8Rvo4i4rdk",
    language: "english",
    at_home_page: true,
    images: [
      {
        image_url:
          "https://res.cloudinary.com/dtvwe5xxk/image/upload/v1735550372/videos/oxuxhrgqvgmuqsxfz8iy.jpg",
        image_id: "videos/oxuxhrgqvgmuqsxfz8iy",
        source_name: "mnb",
        source_url: "yuo",
        _id: "677265a5ca26c3cd9f33db8f",
      },
    ],
    createdAt: "2024-12-30T09:18:25.339Z",
    updatedAt: "2024-12-30T11:08:52.359Z",
    video_id: "67726561ca26c3cd9f33db84",
  },
];

function Watch() {
  const [isShow, setShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [localVideos, setLocalVideos] = useState(mockData);
  // const [error, setError] = useState(false);

  const { addInCache, getFromCache } = useAppContext();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const fetchVideos = async (language) => {
    // await makeApiCall("GET", `/videos/${language}`);
    await makeApiCall("GET", `/videos/${language}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setVideos(resultRef.current.videos);
        addInCache(`${language}-videos`, resultRef.current.videos);

        // setLocalVideos(resultRef.current.videos);
        // setVideos(videoList.filter((item) => item.language == language));
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  useEffect(() => {
    const data = getFromCache("english-videos");
    if (data) {
      setVideos(data);
    } else {
      fetchVideos("english");
    }
  }, []);

  const selectChangeListener = (e) => {
    const selectedLanguage = e.target.value;
    const data = getFromCache(`${selectedLanguage}-videos`);
    if (data) {
      setVideos(data);
    } else {
      fetchVideos(selectedLanguage);
    }
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // TODO: Add play icon at center of image with animation just like in glance
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Watch - travelBucket </title>
      </Helmet>

      {/* HEADING SECTION */}
      <div className="uk-flex uk-flex-row uk-flex-between change-direction">
        <h3 className="">Discover places by watching videos</h3>

        <div className="language">
          <label for="items">Preferred language: </label>
          <select
            className="uk-select"
            name="items"
            id="items"
            onChange={selectChangeListener}
          >
            {/* <option value="english">select language</option> */}
            <option value="english">English</option>
            <option value="hindi">Hindi</option>
          </select>
        </div>
      </div>

      {showLoading()}
      {errorMessage()}

      {/* VIDEO LIST SECTION  */}
      {!isLoading && videos.length == 0 && <p>Nothing found 😞</p>}

      {videos.length != 0 && <CompleteVideoComponentNew videos={videos} />}

      {/* TESTING LOCAL JSON DATA SECTION ON SEARCH RESULT */}
      {/* <section className="uk-container uk-container-small uk-margin-medium">
        <h2 className="custom-underline">TESTING LOCAL JSON DATA</h2>
        {localVideos.length != 0 && (
          <CompleteVideoComponentNew videos={localVideos} />
        )}
      </section> */}

      {/* <section
        className="uk-grid-match uk-grid-row-medium uk-grid-column-small uk-child-width-1-2@s uk-child-width-1-3@m"
        data-uk-grid
        data-uk-scrollspy="cls: uk-animation-fade; target: .video-container; delay: 200; repeat: true"
      >
        {videos.map((video) => {
          return <CompleteVideoCompoent video={video} />;
        })}
      </section> */}

      {/* TODO: Add play icon at center of image with animation just like in glance*/}
      {/* <h4 style={{ color: "red" }}>
        Add play icon at center of image with animation just like in glance
      </h4> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  select {
    padding: 0.4rem 0.5rem;
    font-size: 0.9rem;
  }

  .language {
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 576px) {
    .change-direction {
      flex-direction: column;
    }
    .language {
      margin-bottom: 0.5rem;
    }
  }
`;
export default Watch;
