import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { FaRegBookmark, FaBookmark, FaHeart, FaRegHeart } from "react-icons/fa";
import { Helmet } from "react-helmet";

import mockData from "../mockDb.js";
import "../mockDb.js";
import Review from "../components/Review.js";
import useApi from "../utils/custom-hooks/useApi.js";
import Loading from "../components/Loading.js";
import {
  defaultImage,
  getUser,
  errorImage,
  imageNotFound,
} from "../utils/utility.js";
import BucketListAndForm from "../components/BucketListAndForm.js";
import CustomModal from "../components/CustomModal.js";
import BookmarkModal from "../components/BookmarkModal.js";
import useModal from "../utils/custom-hooks/useModal.js";
import HorizontalImageGallery from "../components/HorizontalImageGallery.js";
import HTMLRenderer from "./admin/components/HTMLRenderer.js";
import RichTextEditor from "./admin/components/RichTextEditor.js";

function FinalDestinationRichText() {
  const { placeId } = useParams();
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [destination, setDestination] = useState();
  const [comments, setComments] = useState([]);

  const [place, setPlace] = useState(mockData[0].destination_places[0]);
  // const [error, setError] = useState(false);
  // const destinationUrl = url + id;

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const { isShow, toggleModal } = useModal();

  const [modalPlaceId, setModalPlaceId] = useState("");
  const [bookmarkClickCount, setBookmarkClickCount] = useState(0);
  const [alreadyBookmarked, setAlreadyBookmarked] = useState(false);

  useEffect(() => {
    fetchFinalDestination();
  }, []);

  const fetchFinalDestination = async () => {
    await makeApiCall("GET", `/final-destination/${placeId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setDestination(resultRef.current.destination);
        setComments(resultRef.current.comments);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const bookmarkBtnClick = (id, isBookmarked) => {
    // setBookmarkClickCount(bookmarkClickCount + 1);
    setModalPlaceId(id);
    setAlreadyBookmarked(isBookmarked);
    toggleModal();
  };

  const updateData = (placeId, newValue) => {
    setDestination({
      ...destination,
      is_bookmarked: newValue,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
          <img src={errorImage} alt="error" className="error-image" />
        </>
      );
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {errorMessage()}
      {destination && (
        <div>
          <Helmet>
            <title>{destination.place_name} - travelBucket </title>
          </Helmet>
          <HorizontalImageGallery imageList={destination.images} />

          {/* Final Destination image section with bookmark */}
          {/* <div className="img-container">
            <img
              className="uk-margin-remove-bottom"
              src={
                destination.images && destination.images[0]
                  ? destination.images[0].image_url
                  : defaultImage
              }
              alt={destination.place_name}
              // onError={({ currentTarget }) => {
              //   currentTarget.onerror = null; // prevents looping
              //   currentTarget.src =
              //     "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Stadtbild_M%C3%BCnchen.jpg/2560px-Stadtbild_M%C3%BCnchen.jpg";
              // }}
              onError={(e) => {
                if (e.target.src !== imageNotFound) {
                  e.target.src = imageNotFound;
                }
              }}
            />

            <div className="bookmark-container">
              {destination && user && destination.is_bookmarked ? (
                <FaHeart
                  className="bookmark-icon"
                  onClick={() => bookmarkBtnClick(destination.place_id, true)}
                />
              ) : (
                <FaRegHeart
                  className="bookmark-icon"
                  data-uk-tooltip="title: add me in your bucket; pos: bottom-left"
                  onClick={() => bookmarkBtnClick(destination.place_id, false)}
                />
              )}
            </div>

          </div> */}

          {/* bookmark */}
          {/* {destination && user && destination.is_bookmarked ? (
            <div className="uk-text-right">
              <FaBookmark
                className="bookmark-icon"
                onClick={() => bookmarkBtnClick(destination.place_id, true)}
              ></FaBookmark>
            </div>
          ) : (
            <div className="uk-text-right">
              <FaRegBookmark
                className="bookmark-icon"
                onClick={() => bookmarkBtnClick(destination.place_id, false)}
              />
            </div>
          )} */}

          <div className="uk-flex uk-flex-right">
            {destination && user && destination.is_bookmarked ? (
              <span className="bg">
                <FaHeart
                  className="bookmark-icon-2"
                  onClick={() => bookmarkBtnClick(destination.place_id, true)}
                />
              </span>
            ) : (
              <span className="bg">
                <FaRegHeart
                  className="bookmark-icon-2"
                  data-uk-tooltip="title: add me in your bucket; pos: bottom-left"
                  onClick={() => bookmarkBtnClick(destination.place_id, false)}
                />
              </span>
            )}
          </div>

          {/*  */}
          {/* {modalPlaceId && (
            <BucketListAndForm
              modalPlaceId={modalPlaceId}
              bookmarkClickCount={bookmarkClickCount}
              alreadyBookmarked={alreadyBookmarked}
              updateData={updateData}
            />
          )} */}

          {/* MODAL SECTION */}
          <CustomModal isShow={isShow} closeModal={toggleModal}>
            <BookmarkModal
              modalPlaceId={modalPlaceId}
              alreadyBookmarked={alreadyBookmarked}
              updateData={updateData}
            />
          </CustomModal>

          <h1 className="uk-h2 uk-margin-small-top">
            {destination.place_name}
          </h1>
          <HTMLRenderer htmlContent={destination.place_info} />
          {/* <RichTextEditor
            theme="bubble"
            value={destination.place_info}
            readOnly={true}
          /> */}

          {/* <p className="uk-text-mute">{destination.place_uniqueness}</p> */}
          {/* <h3 className="uk-h3 uk-remove-margin-top">Overview</h3> */}
          {/* <p className="">{destination.place_info}</p> */}

          {/* <h3 className="uk-h3">What place offers</h3>
          <p className="">{destination.what_place_offers}</p> */}

          {/* {destination &&
            destination.travel_tips &&
            destination.travel_tips.length > 0 && (
              <>
                <h3 className="uk-h3">Travel Tips</h3>
                <ul className="uk-list uk-list-square">
                  {destination &&
                    destination.travel_tips.map((it) => {
                      return <li key={it._id}>{it.item}</li>;
                    })}
                </ul>
              </>
            )} */}

          {/* {destination &&
            destination.not_to_miss &&
            destination.not_to_miss.length > 0 && (
              <>
                <h3 className="uk-h3">Not to Miss</h3>
                <ul className="uk-list uk-list-square">
                  {destination &&
                    destination.not_to_miss.map((it) => {
                      return <li key={it._id}>{it.item}</li>;
                    })}
                </ul>
              </>
            )} */}

          <Review
            allReviews={comments}
            placeId={placeId}
            fromDestination={true}
          />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
    margin-bottom: 2rem;
  }
  p {
    text-align: justify;
  }
  .img-container {
    position: relative;
    /* background: #ebf0f4; */
    background: var(--imageBackgroundColor);
    height: 20rem;

    /* border: 2px solid red; */
  }
  .bookmark-container {
    position: absolute;
    /* width: 2rem; */
    /* height: 2rem; */
    padding: 0.45rem;
    background: #fff;
    right: 1rem;
    bottom: 1rem;
    z-index: 5;
    /* border-radius: 50%; */
  }
  .bg {
    margin-top: 0.5rem;
    padding: 0.45rem;
    /* background: var(--color--accent); */
    background: var(--color--primary);
    z-index: 6;
  }

  .bookmark-icon-2 {
    /* color: #338280; */
    color: #fff;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .bookmark-icon-2:hover {
    width: 1.5rem;
    height: 1.5rem;
    /* color: #1a5e5b; */
  }
  @media screen and (max-width: 767px) {
    .img-container {
      height: 16rem;
    }
  }
`;
export default FinalDestinationRichText;
