import React, { useState, useEffect, useRef } from "react";
import mockData from "./../mockDb";
import styled from "styled-components";
import {
  Link,
  useParams,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { FiThumbsUp, FiEdit2, FiTrash } from "react-icons/fi";
import { MdRateReview, MdOutlineRateReview } from "react-icons/md";
import { Rating } from "react-simple-star-rating";

import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";

import { getUser } from "../utils/utility";
import { toast } from "react-hot-toast";
import CustomModal from "./CustomModal";
import useModal from "../utils/custom-hooks/useModal";
import { useAppContext } from "../contexts/appContext";

function Review({ allReviews, placeId, fromDestination }) {
  const user = getUser();
  const history = useHistory();
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [userReview, setUserReview] = useState();
  const [commentId, setCommentId] = useState("");
  const [reviews, setReviews] = useState(allReviews);
  const [item, setItem] = useState({
    title: "",
    comment: "",
    rate: 0,
    error: false,
  });
  const { isShow, toggleModal } = useModal();
  const reviewTitleRefs = useRef(null);
  const deleteReviewRef = useRef(null);

  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewComment, setReviewComment] = useState("");
  const [reviewRating, setReviewRating] = useState("");

  const [showReviewForm, setShowReviewForm] = useState(false);
  const { addInCache, getFromCache } = useAppContext();

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // const unsignedReview = sessionStorage.getItem('unsigned-review');
    // if(unsignedReview){
    //   setItem(unsignedReview)
    // }
    checkAlreadyReviewShared();
  }, []);

  useEffect(() => {
    updateMainDestinationCacheData();
  }, [reviews]);

  const checkAlreadyReviewShared = () => {
    if (user) {
      const itemExist = reviews.find((it) => it.user.user_id === user.user_id);
      console.log("checkAlreadyReviewShared with FIND: ", itemExist);
      if (itemExist) {
        setUserReview(itemExist);
      }
    }
  };

  const updateMainDestinationCacheData = () => {
    const result = getFromCache(placeId);
    if (result) {
      const newResult = { ...result, comments: reviews };
      addInCache(placeId, newResult);
    }
  };
  const myReviewBtnClickHandler = (review) => {
    console.log("MYREVIEW BUTTON: ", review);
    setShowReviewForm(true);
    setCommentId(review.comment_id);
    setItem({
      ...item,
      title: review.title,
      comment: review.comment,
      error: false,
    });
    toggleModal();
  };

  const editClick = (review) => {
    setCommentId(review.comment_id);
    setItem({
      ...item,
      title: review.title,
      comment: review.comment,
    });
    // setValue("reviewTitle", review.title);
    // setValue("reviewComment", review.comment);

    // TODO: focus on input should be via useRef not directly changing the DOM
    reviewTitleRefs.current?.focus();
    // const dt = document.getElementById("form-stacked-text");
    // dt.focus();
  };

  const deleteClick = async (review) => {
    toggleModal();

    //  setCommentId(review.comment_id);
    //  setValue("reviewTitle", review.title);
    //  setValue("reviewComment", review.comment);

    await makeApiCall(
      "DELETE",
      `/comment/delete/${review.comment_id}/${user.user_id}`
    );

    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        toast.success(`${resultRef.current.message}`);
        setReviews(resultRef.current.comments);
        setUserReview(null);
        setCommentId(null);
        setItem({
          ...item,
          title: "",
          comment: "",
          rate: 0,
          error: false,
        });
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const showDeleteConfirmationDialog = (review) => {
    toggleModal();
    deleteReviewRef.current = review;
  };

  const addReview = async (e) => {
    e.preventDefault();
    if (!user) {
      // sessionStorage.setItem("unsigned-review", JSON.stringify(item));
      alert("You have to login first");
      redirectUser();
    }

    if (commentId) {
      //EDIT
      if (!item.title || !item.comment) {
        setItem({
          ...item,
          error: true,
        });
        return;
      }
      toggleModal();
      const editedReview = {
        comment_id: commentId,
        title: item.title,
        comment: item.comment,

        // title: data.reviewTitle,
        // comment: data.reviewComment,
      };

      await makeApiCall("PUT", `/comment/edit/${user.user_id}`, editedReview);
    } else {
      //POST

      if (!item.title || !item.comment || item.rate === 0) {
        setItem({
          ...item,
          error: true,
        });
        return;
      }

      const review = {
        title: item.title,
        comment: item.comment,
        rating: item.rate,
        user_id: user.user_id,
        place_id: placeId,
        // title: data.reviewTitle,
        // comment: data.reviewComment,
      };

      await makeApiCall("POST", `/comment`, review);
    }

    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success(`${resultRef.current.message}`);
        if (commentId) setCommentId("");

        setItem({
          ...item,
          title: "",
          comment: "",
          rate: 0,
          error: false,
        });
        // setValue("reviewTitle", "");
        // setValue("reviewComment", "");

        setReviews(resultRef.current.comments);
        // checkAlreadyReviewShared();
        const itemExist = resultRef.current.comments.find(
          (it) => it.user.user_id === user.user_id
        );
        console.log("checkAlreadyReviewShared updated: ", itemExist);
        if (itemExist) {
          setUserReview(itemExist);
        }
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);

        if (commentId) setCommentId("");
      }
    }
  };

  const updateReview = async () => {
    //EDIT
    if (!item.title || !item.comment) {
      setItem({
        ...item,
        error: true,
      });
      return;
    }
    toggleModal();
    const editedReview = {
      comment_id: commentId,
      title: item.title,
      comment: item.comment,

      // title: data.reviewTitle,
      // comment: data.reviewComment,
    };

    await makeApiCall("PUT", `/comment/edit/${user.user_id}`, editedReview);

    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success(`${resultRef.current.message}`);
        setCommentId("");
        setItem({
          ...item,
          title: "",
          comment: "",
          rate: 0,
          error: false,
        });
        // setValue("reviewTitle", "");
        // setValue("reviewComment", "");

        setReviews(resultRef.current.comments);
        // checkAlreadyReviewShared();
        const itemExist = resultRef.current.comments.find(
          (it) => it.user.user_id === user.user_id
        );
        console.log("checkAlreadyReviewShared updated: ", itemExist);
        if (itemExist) {
          setUserReview(itemExist);
        }
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
        setCommentId("");
      }
    }
  };

  const handleRating = (rate) => {
    // setRating(rate);
    setItem({
      ...item,
      rate: rate,
    });
  };

  const changeListener = (e) => {
    const { name, value } = e.target;

    setItem({
      ...item,
      [name]: value,
    });
  };

  const redirectUser = () => {
    history.push("/login", {
      from: location.pathname,
    });
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const tooltipBtnStyle = {
    fontSize: "14px",
    borderRadius: "4px",
    padding: "2px 8px",
  };

  const reviewForm = () => {
    return (
      <section>
        {/* handleSubmit(submitReview) */}
        <form className="uk-form-stacked" onSubmit={addReview}>
          {!commentId && (
            <div className="uk-margin">
              <span className="uk-form-label">Rate your experience</span>
              <Rating
                initialValue={item.rate}
                onClick={handleRating}
                transition={true}
                size={30}
                showTooltip={true}
                tooltipStyle={tooltipBtnStyle}
                tooltipArray={[
                  "Terrible",
                  "Bad",
                  "Average",
                  "Great",
                  "Prefect",
                ]}
              />
              <p className="uk-text-danger uk-margin-remove-top">
                {item.error && item.rate === 0 && "add your rating"}
              </p>
            </div>
          )}

          {/* Title */}
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Add a title
            </label>
            <div className="uk-form-controls">
              <input
                ref={reviewTitleRefs}
                className="uk-input"
                id="form-stacked-text"
                type="text"
                name="title"
                placeholder="sum up your experience in one line. what's most important to know?"
                value={item.title}
                onChange={changeListener}
                // onChange={(e) => setReviewTitle(e.target.value)}
                // {...register("reviewTitle", mandatoryField)}
              />
              <span className="uk-text-danger">
                {item.error && !item.title && "field is required"}
              </span>
            </div>
          </div>

          {/* review */}
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-review-id">
              Add your experience
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                id="form-review-id"
                type="text"
                name="comment"
                placeholder="Tell about your experience..."
                rows={4}
                value={item.comment}
                onChange={changeListener}
                // maxLength={100}
                // onChange={(e) => setReviewComment(e.target.value)}
                // {...register("reviewComment", mandatoryField)}
              />
              <span className="uk-text-danger">
                {item.error && !item.comment && "field is required"}
              </span>
            </div>
          </div>

          <button className="uk-button uk-button-secondary" type="submit">
            {commentId ? "update" : "Submit"}
          </button>
        </form>
      </section>
    );
  };

  const reviewList = () => {
    return (
      <section className="uk-flex uk-flex-column">
        {reviews.map((review) => {
          const firstChar = review.user.name.slice(0, 1);
          return (
            <article
              key={review.comment_id}
              className="uk-flex uk-margin-small-top"
            >
              <div className="alphabet-container">
                <span>{firstChar.toUpperCase()}</span>
              </div>
              <div>
                <p className="uk-text-lead uk-margin-remove-bottom name-text">
                  {review.user.name}
                </p>
                <Rating
                  size={16}
                  initialValue={review.rating}
                  readonly={true}
                />
                <p className="uk-margin-remove-top uk-margin-small-bottom">
                  {review.comment}
                </p>
                {/* TODO: LIKE feature of comment */}
                {/* <FiThumbsUp className="like-icon uk-margin-small-right"></FiThumbsUp> */}
                {/* {user && user.user_id === review.user.user_id && (
                  <>
                    <FiEdit2
                      className="edit-icon uk-margin-small-right"
                      data-uk-tooltip="title: edit comment; pos: bottom-left"
                      onClick={() => editClick(review)}
                    ></FiEdit2>
                    <FiTrash
                      className="tb-icon"
                      data-uk-tooltip="title: delete comment; pos: bottom-left"
                      onClick={() => showDeleteConfirmationDialog(review)}
                      // onClick={() => deleteClick(review)}
                    ></FiTrash>
                  </>
                )} */}
              </div>
            </article>
          );
        })}
      </section>
    );
  };

  // MAIN UI
  return (
    <Wrapper className="uk-margin-medium-bottom">
      {/* <div className="uk-h2 uk-margin-medium-top">Travel experiences</div> */}
      {/* <div className="uk-h2 uk-margin-medium-top">Reviews</div> */}
      <div className="uk-h2 uk-margin-small-top">Share your experience</div>
      {userReview && (
        <button
          className="uk-button uk-button-primary"
          // onClick={() => editClick(userReview)}
          onClick={() => myReviewBtnClickHandler(userReview)}
        >
          <MdOutlineRateReview className="review-icon" /> my Review
        </button>
      )}
      {/* <--- BUTTONS SECTION ---> */}
      {/* <div className="uk-flex uk-flex-row uk-flex-between">
        <div>
          <button className="uk-button uk-button-secondary">
            (icon) Write a Review
          </button>
          <p className="uk-text-warning">* required login to comment</p>
        </div>
        <div>
          <button className="uk-button uk-button-primary">Login</button>
          <button className="uk-button uk-button-default uk-margin-small-left">
            Register
          </button>
        </div>
      </div> */}

      {!user && (
        <div className="uk-flex">
          <div>
            <button
              className="uk-button uk-button-primary"
              onClick={redirectUser}
            >
              Log in
            </button>
          </div>

          {/* <Link to="/login">
            <button className="uk-button uk-button-primary">Log in</button>
          </Link> */}
          <p className="uk-text-warning uk-margin-small-left">
            *please login first to comment
          </p>
        </div>
      )}

      {showLoading()}
      {errorMessage()}
      {!userReview && reviewForm()}
      {reviewList()}
      {/* {redirect && <Redirect to={`/login`} />} */}
      <CustomModal isShow={isShow} closeModal={toggleModal}>
        {/* {showReviewForm ? (
          <>
            <h4>My Review</h4>
            <form className="uk-form-stacked" onSubmit={submitReview}>
              <div className="uk-margin">
                <input
                  className="uk-input"
                  type="text"
                  name="title"
                  placeholder="sum up your experience in one line. what's most important to know?"
                  value={item.title}
                  onChange={changeListener}
                />
                <span className="uk-text-danger">
                  {item.error && !item.title && "field is required"}
                </span>
              </div>

              <div className="uk-margin">
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    type="text"
                    name="comment"
                    placeholder="Tell about your experience..."
                    rows={4}
                    value={item.comment}
                    onChange={changeListener}
                  />
                  <span className="uk-text-danger">
                    {item.error && !item.comment && "field is required"}
                  </span>
                </div>
              </div>

              <button className="uk-button uk-button-secondary" type="submit">
                update
              </button>
            </form>

            <button
              className="uk-button uk-button-danger uk-button-smal uk-margin-small-right"
              onClick={() => deleteClick(userReview)}
            >
              delete
            </button>

            <button
              className="uk-button uk-button-default uk-button-smal"
              onClick={() => toggleModal()}
            >
              close
            </button>
          </>
        ) : (
          <>
            <p>tell me</p>
          </>
        )} */}

        <>
          <div className="uk-flex uk-flex-between">
            <h4>My Review</h4>
            <div>
              {/* <button
                className="uk-button uk-button-danger uk-button-smal uk-margin-small-rigt"
                onClick={() => deleteClick(userReview)}
              >
                delete
              </button> */}
            </div>
          </div>

          {/* <form className="uk-form-stacked">
            <div className="uk-margin">
              <input
                className="uk-input"
                type="text"
                name="title"
                placeholder="sum up your experience in one line. what's most important to know?"
                value={item.title}
                onChange={changeListener}
              />
              <span className="uk-text-danger">
                {item.error && !item.title && "field is required"}
              </span>
            </div>

            <div className="uk-margin">
              <div className="uk-form-controls">
                <textarea
                  className="uk-textarea"
                  type="text"
                  name="comment"
                  placeholder="Tell about your experience..."
                  rows={4}
                  value={item.comment}
                  onChange={changeListener}
                />
                <span className="uk-text-danger">
                  {item.error && !item.comment && "field is required"}
                </span>
              </div>
            </div>

            <div>
              <button
                className="uk-button uk-button-secondary"
                onClick={submitReview}
              >
                update
              </button>
            </div>
          </form> */}

          <div>
            <div className="uk-margin">
              <input
                className="uk-input"
                type="text"
                name="title"
                placeholder="sum up your experience in one line. what's most important to know?"
                value={item.title}
                onChange={changeListener}
              />
              <span className="uk-text-danger">
                {item.error && !item.title && "field is required"}
              </span>
            </div>
            <div className="uk-margin">
              <textarea
                className="uk-textarea"
                type="text"
                name="comment"
                placeholder="Tell about your experience..."
                rows={4}
                value={item.comment}
                onChange={changeListener}
              />
              <span className="uk-text-danger">
                {item.error && !item.comment && "field is required"}
              </span>
            </div>
            <div className="uk-margin-small-top">
              <button
                className="uk-button uk-button-secondary"
                onClick={updateReview}
              >
                update
              </button>
              <button
                className="uk-button uk-button-danger uk-button-smal uk-margin-small-left"
                onClick={() => deleteClick(userReview)}
              >
                delete
              </button>
            </div>
          </div>
        </>
      </CustomModal>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .name-text {
    font-size: 1.25rem;
  }
  .alphabet-container {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
    background-color: #48647f;
    /* background-color: #be0909; */
    border-radius: 1.5rem;
    margin-right: 0.5rem;
  }

  .like-icon {
    /* color: #9aa0a6; */
    color: #70757a;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .like-icon:hover {
    color: #459aee;
  }
  .edit-icon {
    /* grey icon */
    color: #666;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .edit-icon:hover {
    color: #575656;
  }

  .review-icon {
    /* color: #70757a; */
    width: 1.1rem;
    height: 1.1rem;
    /* cursor: pointer; */
  }

  @media (max-width: 425px) {
    .alphabet-container {
      visibility: hidden;
      width: 0;
      height: 0;
      min-width: 0;
      min-height: 0;
      margin-right: 0;
    }
  }
`;

export default Review;
