import React, { useMemo } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css"; // bubble theme

// import "./customQuillStyles.css";

const HTMLRenderer = ({ htmlContent, className = "" }) => {
  // rendering rawHTML cab be done 3 ways
  // 1. ReactQuill Editor itself readOnly = true & theme = "bubble"
  // 2. dangerouslySetInnerHTML (less secure)
  // 3. html-react-parser (a bit more secure)

  const defaultModules = useMemo(
    () => ({
      toolbar: {
        container: [
          //   [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          ["clean"],
        ],
        handlers: {
          image: "",
        },
      },
      // want additional line break (<p><br/><p/>) or not on copy-paste HTML
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <Wrapper>
      {/* // option 2: dangerouslySetInnerHTML */}
      {/* <div
        className={className}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      /> */}

      {/* // option 3: html-react-parser */}
      <div>{parse(htmlContent)}</div>

      {/* // option 1: */}

      {/*   <ReactQuill
        theme="bubble"
        modules={defaultModules}
        value={htmlContent}
        readOnly={true}
      />
       */}
    </Wrapper>
  );
};

export default HTMLRenderer;

const Wrapper = styled.div`
  /* NORMAL raw-HTML or dangerouslySetInnerHTML CUSTOMIZATION */

  /* avoiding the additional blank spaces caused by default tag margins */
  /* Removes default margin of UI KIT lirary or index CSS file */
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li {
    margin: 0;
    text-align: justify;
    /* color: #666; */
    /* padding: 0; */
  }

  ul {
    list-style-type: square;
    margin: 0rem;
    padding: 0rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  em {
    /* color: inherit !important; */
    /* font-style: italic; */
  }

  /* REACT-QUILL-EDITOR CUSTOMIZATION */
  .ql-editor {
    padding: 0;
  }
  /* background: #d70b0b; */
  /* .ql-editor ul {
    list-style-type: square;
    margin: 0rem;
    padding: 0rem;
    padding-left: 1.5rem;
  }
  .ql-editor li {
    margin-bottom: 0.5rem;
  } */
`;
