import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Redirect, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import Loading from "../../components/Loading";
import useApi from "../../utils/custom-hooks/useApi";

function ImageListing() {
  const { mediumType, mediumId } = useParams(); //mediumType supports three value: article, destination, video
  const [listedImages, setListedImages] = useState([]);

  const imageInputRef = useRef(null);
  const [sourceName, setSourceName] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [currentImage, setCurrentImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesInfos, setImagesInfos] = useState([]); //{file_name: '', source_name: "", source_url: ""}
  const [locallyUploadedImages, setLocallyUploadedImages] = useState([]);

  const [addAction, setAddAction] = useState(false);
  const [removeAction, setRemoveAction] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);

  const [selectedUpdateImageIndex, setSelectedUpdateImageIndex] = useState(-1);

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchDestinationImages();
  }, []);

  const fetchDestinationImages = async () => {
    // await makeApiCall("GET", `/destination/images/${placeId}`);
    await makeApiCall("GET", `/images/${mediumType}/${mediumId}`);
    if (resultRef.current && resultRef.current.status) {
      const destImages = resultRef.current.images;
      setListedImages(destImages);
    }
  };

  const handleOnChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setCurrentImage(selectedImage);
    }
  };

  const addImage = (e) => {
    e.preventDefault();

    if (!currentImage || !sourceName || !sourceUrl) {
      alert("Please fill all 3 field info");
      return;
    }

    const newImageInfo = {
      //   image_file: currentImage,
      file_name: currentImage.name,
      source_name: sourceName,
      source_url: sourceUrl,
    };

    // setImages([...images, currentImage]);
    // setImagesInfos([...imagesInfos, newImageInfo]);

    setImages((prevImages) => [...prevImages, currentImage]);
    setImagesInfos((prevImagesInfos) => [...prevImagesInfos, newImageInfo]);

    setCurrentImage(null);
    setSourceName("");
    setSourceUrl("");

    // Reset the file input field
    imageInputRef.current.value = null;
  };

  const removeImage = (selectedIndex) => {
    const newImageList = images.filter((image, index) => {
      return index !== selectedIndex;
    });
    const newImagesInfos = imagesInfos.filter(
      (item, index) => index !== selectedIndex
    );

    setImages(newImageList);
    setImagesInfos(newImagesInfos);
  };

  const onSubmit = async (data) => {
    if (imagesInfos.length === 0) {
      alert("upload atleast 1 image locally first");
      return;
    }
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    formData.append("images_infos", JSON.stringify(imagesInfos));
    formData.append("medium_type", mediumType);
    formData.append("medium_id", mediumId);

    await makeApiCall("POST", `/admin/images`, formData);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success(resultRef.current.message);

        const destImages = resultRef.current.images;
        setListedImages(destImages);

        // clear locally uploaded images
        setImages([]);
        setImagesInfos([]);
      } else {
        toast.error(resultRef.current.message);
      }
    }
  };

  const updateImageInfoHandler = async (data) => {
    if (!data.imageId) {
      alert("no image selected, click on any SELECT button to choose");
      return;
    }

    const request = {
      image_id: data.imageId,
      source_name: data.sourceName,
      source_url: data.sourceUrl,
      medium_id: mediumId,
      medium_type: mediumType,
    };

    await makeApiCall("PUT", `/admin/images`, request);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success(resultRef.current.message);

        const destImages = resultRef.current.images;
        setListedImages(destImages);
        setValue("imageId", "");
        setValue("sourceName", "");
        setValue("sourceUrl", "");
        setValue("hostedImageUrl", "");
        setSelectedUpdateImageIndex(-1);
      } else {
        toast.error(resultRef.current.message);
      }
    }
  };

  const updateButtonClick = (image, selectedIndex) => {
    setValue("imageId", image.image_id);
    setValue("sourceName", image.source_name);
    setValue("sourceUrl", image.source_url);
    setValue("hostedImageUrl", image.image_url);
    setSelectedUpdateImageIndex(selectedIndex);
  };

  const removeImageHandleClick = async () => {
    var checkedValues = [];
    var inputElements = document.getElementsByClassName("uk-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValues.push(inputElements[i].value);
      }
    }
    if (checkedValues.length === 0) {
      alert("no image selected");
      return;
    }

    const request = {
      medium_id: mediumId,
      medium_type: mediumType,
      deleted_images: checkedValues,
    };
    await makeApiCall("PUT", `/admin/images/delete`, request);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success(resultRef.current.message);

        const destImages = resultRef.current.images;
        setListedImages(destImages);
      } else {
        toast.error(resultRef.current.message);
      }
    }
  };

  /**
   
  function fileParams() {
    // Data type - File
    // Locally
    lastModified: 1605458826620;
    lastModifiedDate: "Sun Nov 15 2020 22:17:06 GMT+0530 (India Standard Time) {}";
    name: "snap.PNG";
    size: 205359;
    type: "image/png";
    webkitRelativePath: "";

    // multer
    destination: "C:\\Users\\LENOVO\\AppData\\Local\\Temp";
    encoding: "7bit";
    fieldname: "images";
    filename: "f69e3e73f158258b59ae1300b5bb9ab8";
    mimetype: "image/png";
    originalname: "snap.PNG";
    path: "C:\\Users\\LENOVO\\AppData\\Local\\Temp\\f69e3e73f158258b59ae1300b5bb9ab8";
    size: 185973;
  }
*/

  // MINI UI [UI SUB-SECTION]
  const uploadImagesForm = () => {
    return (
      <section className="uk-margin-medium-top">
        <form
          class="uk-form-stacked"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="uk-width-3-4 uk-flex uk-flex-between">
            <input
              className="uk-margin-small-bottom"
              type="file"
              // name="image"
              onChange={handleOnChange}
              ref={imageInputRef}
            />
            <button
              className="uk-button uk-button-primary uk-margin-small-top"
              onClick={addImage}
            >
              Add locally
            </button>
          </div>

          <div class="uk-margin">
            <input
              className="uk-input uk-width-3-4"
              type="text"
              placeholder="source name (author name) (platform name)"
              value={sourceName}
              onChange={(e) => setSourceName(e.target.value)}
            />
          </div>
          <div class="uk-margin">
            <input
              className="uk-input uk-width-3-4"
              type="text"
              placeholder="source url (original image url from where image is extracted)"
              value={sourceUrl}
              onChange={(e) => setSourceUrl(e.target.value)}
            />
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-small-bottom"
            type="submit"
          >
            submit
          </button>
        </form>

        <section className="uk-grid uk-grid-small uk-child-width-1-4">
          {images.map((imageFile, index) => {
            return (
              <div className="uk-margin-small-bottom" key={index}>
                <img
                  alt={imageFile.name}
                  //   width={"250px"}
                  src={URL.createObjectURL(imageFile)}
                />
                <button onClick={() => removeImage(index)}>Remove</button>
              </div>
            );
          })}
        </section>
      </section>
    );
  };

  const updateImageInfoForm = () => {
    return (
      <section>
        <form
          class="uk-form-stacked"
          onSubmit={handleSubmit(updateImageInfoHandler)}
        >
          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="image-id"
              // value={sourceName}
              // onChange={(e) => setSourceName(e.target.value)}
              {...register("imageId")}
              disabled
            />
            <input
              class="uk-input"
              type="text"
              placeholder="source name (author name) (platform name)"
              // value={sourceName}
              // onChange={(e) => setSourceName(e.target.value)}
              {...register("sourceName")}
            />
            <input
              class="uk-input"
              type="text"
              placeholder="source url (original image url from where image is extracted)"
              // value={sourceUrl}
              // onChange={(e) => setSourceUrl(e.target.value)}
              {...register("sourceUrl")}
            />
            <input
              class="uk-input"
              type="text"
              placeholder="hosted-image-url"
              // value={sourceName}
              // onChange={(e) => setSourceName(e.target.value)}
              {...register("hostedImageUrl")}
              disabled
            />
            <button
              className="uk-button uk-button-secondary uk-margin-small-top"
              // onClick={addImage}
              type="submit"
            >
              update
            </button>
          </div>
        </form>
      </section>
    );
  };

  const showDestinationImages = () => {
    return (
      <div className="uk-margin-medium-top">
        <h2>All Images</h2>
        {listedImages.length === 0 && <h5>no images found.</h5>}
        <section className="uk-grid uk-grid-small uk-child-width-1-4">
          {listedImages &&
            listedImages.map((image, index) => {
              return (
                <div className="uk-margin-small-bottom" key={image.image_id}>
                  <img
                    alt="not found"
                    //   width={"250px"}
                    src={image.image_url}
                  />

                  {removeAction && (
                    <div className="remove-background uk-flex uk-flex-center uk-flex-middle">
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        value={image.image_id}
                      />
                    </div>
                  )}

                  {updateAction && (
                    <div className="uk-flex uk-flex-center uk-flex-middle">
                      <button
                        onClick={() => updateButtonClick(image, index)}
                        className={`uk-button uk-button-default uk-button-small uk-margin-small-top ${
                          selectedUpdateImageIndex == index && "active-button"
                        }`}
                      >
                        select
                      </button>
                    </div>
                  )}
                  {/* <p className="uk-margin-small"></p> */}
                </div>
              );
            })}
        </section>
      </div>
    );
  };

  const actionSegment = () => {
    return (
      <div>
        <button
          className="uk-button uk-button-default uk-margin-medium-right"
          onClick={() => setAddAction(!addAction)}
        >
          {addAction ? "close" : "Add action"}
        </button>
        <button
          className="uk-button uk-button-default uk-margin-medium-right"
          onClick={() => setRemoveAction(!removeAction)}
        >
          {removeAction ? "close" : "Remove action"}
        </button>
        <button
          className="uk-button uk-button-default uk-margin-medium-right"
          onClick={() => setUpdateAction(!updateAction)}
        >
          {updateAction ? "close" : "Update action"}
        </button>
      </div>
    );
  };
  const errorMessage = () => {
    if (error.show) {
      return (
        <h4 className="uk-text-danger uk-text-middle uk-margin-top-small">
          {error.msg}
        </h4>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {showLoading()}
      {errorMessage()}
      <h2 className="uk-margin-small-top">Image Listing</h2>
      {actionSegment()}
      {addAction && uploadImagesForm()}

      {showDestinationImages()}
      {removeAction && (
        <button
          className="uk-button uk-button-danger uk-margin-small-top uk-margin-medium-bottom"
          onClick={removeImageHandleClick}
        >
          remove selected images
        </button>
      )}
      {updateAction && updateImageInfoForm()}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #f2f2f2;
  .remove-background {
    height: 2rem;
    /* background: #000; */
    /* background: #e37383; */
    /* background: #ff69b4; */
    /* background: #f81894; */
    /* background: #002b51; */
    background: #223c50;
  }
  .active-button {
    background-color: #223c50;
    background-color: #002b51;
    /* background-color: #f0506e; */
    color: #fff;
  }
`;

export default ImageListing;
