import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import useApi from "../../utils/custom-hooks/useApi";
import { defaultImage } from "../../utils/utility";
import { useAppContext } from "../../contexts/appContext";
import TabExample from "../../components/TabExample";

function AdminHome() {
  const [destinations, setDestinations] = useState([]);
  const { addInCache, getFromCache } = useAppContext();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  useEffect(() => {
    const data = getFromCache("all-mds");
    if (!data) {
      fetchDestinations();
    } else {
      setDestinations(data);
    }

    // const savedData = sessionStorage.getItem("all-dest");
    // const result = savedData ? JSON.parse(savedData) : null;
    // if (result) {
    //   setDestinations(result);
    //   handleScrollPosition();
    //   // setTimeout(() => {
    //   //   handleScrollPosition();
    //   // }, 500);
    // } else {
    //   fetchDestinations();
    // }
  }, []);

  const fetchDestinations = async () => {
    await makeApiCall("GET", `/admin/main-destinations`);
    if (resultRef.current) {
      if (resultRef.current.destinations) {
        setDestinations(resultRef.current.destinations);
        addInCache(`all-mds`, resultRef.current.destinations);

        // sessionStorage.setItem(
        //   "all-dest",
        //   JSON.stringify(resultRef.current.destinations)
        // );
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const itemClick = () => {
    sessionStorage.setItem("scrollPosition-admin", window.pageYOffset);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition-admin");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition-admin");
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Admin Home - travelBucket </title>
      </Helmet>
      <TabExample />
      <h3>Add/create destinations</h3>
      <div className="uk-flex uk-flex-between">
        <div>
          <Link
            to="/admin/add-destination-rich-text"
            className="uk-button uk-button-secondary uk-margin-small-right"
          >
            Add destination (Rich Text)
          </Link>
          <Link
            to="/admin/add-destination"
            className="uk-button uk-button-secondary uk-button-small"
          >
            Add destination (Plain Text)
          </Link>
          <p className="uk-text-warning">
            includes main destination, final destination, neighbourhood
          </p>
        </div>
        <span>
          <Link
            to="/admin/add-place-type"
            className="uk-button uk-button-secondary uk-button-small"
          >
            Add Country, state, zone
          </Link>
        </span>
      </div>

      {/*  */}
      <h3>Add Video</h3>
      <div className="">
        <Link
          to="/admin/video-page/add/0"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          Upload
        </Link>
        <Link
          to="/admin/video-listing"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          Videos
        </Link>
      </div>

      {/*  */}
      <h3>Add Article</h3>
      <div className="">
        <Link
          to="/admin/editor/write/0"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          write article
        </Link>

        <Link
          to="/admin/articles"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          Articles
        </Link>

        <Link
          to="/admin/editor/tinymce"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          TinyMCE
        </Link>

        <Link
          to="/admin/editor/ck"
          className="uk-button uk-button-secondary uk-margin-medium-right"
        >
          CK
        </Link>
      </div>

      {/*  */}
      <h3>Add/create travel categories & themes</h3>
      <div className="uk-flex uk-flex-left">
        <Link
          to="/admin/add-category-&-theme"
          className="uk-button uk-button-secondary"
        >
          Add Category,theme
        </Link>
      </div>

      <h2>All Destinations</h2>
      {destinations.map((destination) => {
        return (
          <article
            className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover uk-margin-medium-bottom"
            key={destination.place_id}
            onClick={itemClick}
          >
            <div className="remove-underline uk-flex uk-flex-row">
              <img
                loading="lazy"
                className="uk-width-1-3"
                src={
                  destination.images[0]
                    ? destination.images[0].image_url
                    : defaultImage
                }
                alt={destination.place_name}
              />
              <div className="uk-width-2-3 uk-margin-small-left">
                <h3>{destination.place_name}</h3>
                <p className="text-eql-aligned">
                  {destination.place_info_short_description}
                </p>
                {/* <span className="uk-text-primary">... Read more</span> */}
                <Link
                  className="button uk-button uk-button-default uk-margin-small-right"
                  to={`/admin/destination-listing/${destination.place_id}`}
                >
                  admin
                </Link>
                <Link
                  className="button uk-button uk-button-default"
                  to={`/main-destination/${destination.place_id}`}
                >
                  user
                </Link>
              </div>
            </div>
          </article>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .remove-underline {
    text-decoration: none;
  }
`;
export default AdminHome;
