import Axios from "axios";
import * as Sentry from "@sentry/react";

const axios = Axios.create({
  // baseURL: "https://tb-backend-server.onrender.com/api", //render
  baseURL: "https://rare-laurice-travelbucket-a3257387.koyeb.app/api", //koyeb
  // baseURL: "http://localhost:8000/api",

  // baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "https://travelbucket-backend.cyclic.app/api", [Cyclic production server base url]
  // headers: {
  //   "Content-Type": "application/json",
  // },
});
axios.defaults.withCredentials = true;

axios.interceptors.request.use((request) => {
  // console.log(
  //   `---------  REQUEST ----------- \n ${request.method.toUpperCase()} ${
  //     request.url
  //   } with params`,
  //   request.params,
  //   `and data`,
  //   request.data
  // );
  return request;
});

axios.interceptors.response.use((response) => {
  // console.log(`---------  RESPONSE  --------- \n`, ``, response.data);
  //
  // console.log(
  //   `---------  RESPONSE  --------- \n ${response.config.method.toUpperCase()} ${
  //     response.config.url
  //   } with params`,
  //   response.config.params,
  //   `and data`,
  //   response.config.data,
  //   `and got response data ->`,
  //   response.data
  // );
  return response;
});

// axios.interceptors.request.use(
//   (config) => {
//     config.headers.common["Authorization"] = `Bearer ${state.token}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// response

// SENTRY ERROR REPORTING
axios.interceptors.response.use(
  (response) => {
    // status code 2xx
    return response;
  },
  (error) => {
    console.error("-----------  ERRRROR:  ------------", error);
    if (error.response) {
      // outside of 2xx status code i.e., 4xx, 5xx
      // client received an error response (5xx, 4xx)

      console.error("--- RESPONSE RECEIVED ERROR:  ---", error);
    } else if (error.request) {
      // client never received a response, or request never left

      console.error("--- RESPONSE NOT RECEIVED ERROR:  ---", error);
      if (
        !window.navigator.onLine &&
        !error.response &&
        error.code === "ERR_NETWORK"
      ) {
        Sentry.captureException(
          new Error(`No internet connection - ${error.message}`),
          {
            tags: {
              section: "axiosConfig",
            },
          }
        );
        const customMessage = `${error.message} - No internet connection`;
        error.message = "No internet connection";
      } else {
        // TODO: You are not telling user what type of error apart from internet connect error one.
        // In order to track/know what are differnt types of error is creating on live user, simply use Sentry dashboard with title below mentioned message
        Sentry.captureException(
          new Error(
            `Something went wrong on our end: server - ${error.message}`
          ),
          {
            tags: {
              section: "axiosConfig",
            },
          }
        );
        error.message = "Something went wrong on our end";
      }
    } else {
      // mistake in setting up the AXIOS request. It could be something with the data passed, or a configuration setting.
      console.error("--- REQUEST ERROR:  ---", error);
      Sentry.captureException(new Error(`Request Error - ${error.message}`), {
        tags: {
          section: "axiosConfig",
        },
      });
    }

    return Promise.reject(error);
  }
);
export default axios;
