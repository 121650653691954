import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";

import useApi from "../../utils/custom-hooks/useApi";
import Loading from "../../components/Loading";
import { defaultImage } from "../../utils/utility";
import CompleteVideoComponentNew from "../../components/CompleteVideoComponentNew";

function VideoListing() {
  const [videos, setVideos] = useState([]);

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const fetchDestinationVideos = async (language) => {
    // await makeApiCall("GET", `/videos/${language}`);
    await makeApiCall("GET", `/videos/${language}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setVideos(resultRef.current.videos);
        // setVideos(videoList.filter((item) => item.language == language));
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  useEffect(() => {
    fetchDestinationVideos("english");
  }, []);

  const selectChangeListener = (e) => {
    const selectedLanguage = e.target.value;
    fetchDestinationVideos(selectedLanguage);
  };

  const videoListing = () => {
    return (
      <>
        {/* HEADING SECTION */}
        <div className="uk-flex uk-flex-row uk-flex-between change-direction">
          <h3>Uploaded Videos</h3>
          <div className="language">
            <label for="items">Preferred language: </label>
            <select
              // className="uk-select"
              name="items"
              id="items"
              onChange={selectChangeListener}
            >
              {/* <option value="english">select language</option> */}
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
            </select>
          </div>
        </div>
        {videos && videos.length === 0 && <h4>nothing found</h4>}

        <section
          className="uk-grid-match uk-grid-small uk-child-width-1-3"
          data-uk-grid
        >
          {videos &&
            videos.map((video) => {
              return (
                <article
                  className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover"
                  key={video.video_id}
                >
                  <img
                    loading="lazy"
                    // src={video.image ? video.image : defaultImage}
                    src={
                      video.images[0] ? video.images[0].image_url : defaultImage
                    }
                    // src={defaultImage}
                    alt={video.title}
                  />
                  <h5 className="uk-margin-small-top uk-margin-small-bottom">
                    {video.title}
                  </h5>

                  <div>
                    <Link
                      className="button uk-button uk-button-default uk-margin-small-right"
                      to={`/admin/video-page/edit/${video.video_id}`}
                    >
                      edit
                    </Link>
                    <Link
                      className="button uk-button uk-button-default uk-margin-small-right"
                      to={`/admin/image-listing/${"video"}/${video.video_id}`}
                    >
                      images
                    </Link>
                  </div>
                </article>
              );
            })}
        </section>

        {/* <>Recent Video Section</> */}
        <section className="uk-container uk-container-small uk-margin-medium">
          <h2 className="custom-underline">Latest Videos</h2>
          {!isLoading && videos.length == 0 && <p>Nothing found 😞</p>}
          {videos.length != 0 && <CompleteVideoComponentNew videos={videos} />}
        </section>
      </>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Admin Video Listing Page - travelBucket </title>
      </Helmet>
      {showLoading()}
      {errorMessage()}
      {videoListing()}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .remove-underline {
    text-decoration: none;
  }
  select {
    padding: 0.4rem 0.5rem;
    font-size: 0.9rem;
  }
  @media screen and (max-width: 576px) {
    .change-direction {
      flex-direction: column;
    }
    .language {
      margin-bottom: 1rem;
    }
  }
`;

export default VideoListing;
