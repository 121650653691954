import React, { useEffect } from "react";

const TabExample = () => {
  useEffect(() => {
    setTimeout(() => {
      // Manually initialize the UIkit tab component
      if (window.UIkit) {
        window.UIkit.tab(document.querySelector("[data-uk-tab]"));
      } else {
        console.error("UIkit not loaded!");
      }
    }, 3);
  }, []);

  return (
    <div className="uk-container">
      {/* Tab Navigation */}
      <ul className="uk-tab" data-uk-tab>
        <li>
          <a href="#">Tab 1</a>
        </li>
        <li>
          <a href="#">Tab 2</a>
        </li>
        <li>
          <a href="#">Tab 3</a>
        </li>
      </ul>

      {/* Tab Content */}
      <ul className="uk-switcher uk-margin">
        <li>Content for Tab 1</li>
        <li>Content for Tab 2</li>
        <li>Content for Tab 3</li>
      </ul>
    </div>
  );
};

export default TabExample;
