import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Link, useParams, Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FiEdit2, FiTrash } from "react-icons/fi";
import sanitizeHtml from "sanitize-html";

import EditSubDestination from "./components/EditSubDestination";
import EditSubDestinationRichText from "./EditSubDestinationRichText.js";
import {
  mandatoryField,
  themes,
  placeTypes,
  getPlainTextFromRawHTML,
  cleanHtml,
} from "../../utils/utility";
import Loading from "../../components/Loading";
import EditNeighbour from "./components/EditNeighbour";
import {
  MAINDESTINATION,
  FINALDESTINATION,
  NEIGHBOUR,
} from "../../utils/constants";
import useApi from "../../utils/custom-hooks/useApi";

import RichTextEditor from "./components/RichTextEditor";
import HTMLRenderer from "./components/HTMLRenderer";

function EditDestinationRichText() {
  const { placeType, placeId } = useParams();

  const [bestTimeToGoId, setBestTimeToGoId] = useState(null);
  const [bestTimeToGoTitle, setBestTimeToGoTitle] = useState("");
  const [bestTimeToGoInfo, setBestTimeToGoInfo] = useState("");
  const [bestTimeToGoList, setBestTimeToGoList] = useState([]);

  const [howToReachId, setHowToReachId] = useState(null);
  const [howToReachTitle, setHowToReachTitle] = useState("");
  const [howToReachInfo, setHowToReachInfo] = useState("");
  const [howToReachList, setHowToReachList] = useState([]);

  const [thingsToKnowId, setThingsToKnowId] = useState(null);
  const [thingsToKnow, setThingsToKnow] = useState("");
  const [thingsToKnowList, setThingsToKnowList] = useState([]);

  const [videoTitle, setVideoTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [watchInfoId, setWatchInfoId] = useState(null);
  const [watchInfos, setWatchInfos] = useState([]);

  const [moreInfoId, setMoreInfoId] = useState(null);
  const [moreInfoTitle, setMoreInfoTitle] = useState("");
  const [moreInfoDescription, setMoreInfoDescription] = useState("");
  const [moreInfos, setMoreInfos] = useState([]);

  const [makePublish, setMakePublish] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  const [placeOverview, setPlaceOverview] = useState("");
  const [sourceReferences, setSourceReferences] = useState("");

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const fetchMainDestination = async () => {
    await makeApiCall("GET", `/main-destination/${placeId}`);
    if (resultRef.current && resultRef.current.status) {
      const destination = resultRef.current.destination;
      setValue("placeName", destination.place_name);

      const doc = new DOMParser().parseFromString(
        destination.place_info,
        "text/html"
      );

      setPlaceOverview(destination.place_info);

      setValue(
        "countryRadBtn",
        destination.popular_in_country === true ? "yes" : "no"
      );
      setValue(
        "stateRadBtn",
        destination.popular_in_state === true ? "yes" : "no"
      );
      setValue("themeType", destination.theme_type);
      setValue("placeType", "maindestination");
      setValue("streetAddress", destination.address.street_address);
      setValue("latLong", destination.address.lat_long);
      setValue("country", destination.address.country);
      setValue("state", destination.address.state);

      // setMoreInfos(decodedMoreInfoHTML);
      setMoreInfos(destination.more_info);

      setWatchInfos(destination.only_watch);
      setBestTimeToGoList(destination.best_time_to_go);
      setHowToReachList(destination.how_to_reach);
      setThingsToKnow(destination.things_to_know);
      // setThingsToKnowList(destination.things_to_know);
      setMakePublish(destination.is_published);
      setSourceReferences(destination.source_references);
      // setValue("sourceReferences", destination.source_references);
    }
  };

  useEffect(() => {
    if (placeType === "maindestination") fetchMainDestination();
  }, []);

  const onSubmit = async (data) => {
    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      placeOverview.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (!isValidContent) {
      alert("Enter the place overview");
      return;
    }

    const pCountry = data.countryRadBtn === "yes" ? true : false;
    const pState = data.stateRadBtn === "yes" ? true : false;

    // const publishCheckboxElement = document.getElementById("publish-checkbox");
    // const isPublished = publishCheckboxElement.checked;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };

    // Cleaning all raw HTML content before sending to backend
    const placeInfoShortDescription = getPlainTextFromRawHTML(placeOverview);
    const placeOverviewClean = cleanHtml(placeOverview);
    const thingsToKnowClean = cleanHtml(thingsToKnow);
    const sourceReferencesClean = cleanHtml(sourceReferences);
    const moreInfosClean = moreInfos.map((item) => {
      const descriptionClean = cleanHtml(item.description);
      return { ...item, description: descriptionClean };
    });

    const howToReachListClean = howToReachList.map((item) => {
      const infoClean = cleanHtml(item.info);
      return { ...item, info: infoClean };
    });

    const bestTimeToGoListClean = bestTimeToGoList.map((item) => {
      const infoClean = cleanHtml(item.info);
      return { ...item, info: infoClean };
    });

    const dest = {
      place_id: placeId,
      place_name: data.placeName,
      place_info: placeOverviewClean,
      place_info_short_description: placeInfoShortDescription,
      // things_to_know: thingsToKnowList,
      things_to_know: thingsToKnowClean,
      more_info: moreInfosClean,
      how_to_reach: howToReachListClean,
      popular_in_country: pCountry,
      popular_in_state: pState,
      theme_type: data.themeType,
      place_type: "maindestination",
      destination_type: "maindestination",
      only_watch: watchInfos, //DURING EDIT as data might not be avaiable while adding
      best_time_to_go: bestTimeToGoListClean,
      address: address,
      is_published: makePublish,
      source_references: sourceReferencesClean,
    };

    await makeApiCall("PUT", `/admin/main-destination/edit`, dest);
    console.log("AFTER API CALL ");

    if (resultRef.current && resultRef.current.status) {
      alert(`SUCCESS:  ${resultRef.current.message}`);
      // setRedirect(true);
      // Approach 1
      // Redirect to MainDestination with query parameter indicating data update
      //
      //   UNCOMMENT JUST THIS PART
      history.replace(
        `/admin/destination-listing/${placeId}?refreshRequired=true`
      );

      // Approach 2
      // Navigate back to MainDestination with query parameter indicating data update
      // history.push(
      //   `/admin/destination-listing/${placeId}?refreshRequired=true`
      // );

      // // Replace the current entry to clean up history
      // setTimeout(() => {
      //   history.replace(
      //     `/admin/destination-listing/${placeId}?refreshRequired=true`
      //   );
      // }, 0);

      // Approach 3
      // Navigate back to MainDestination with state indicating data update
      // history.push(`/admin/destination-listing/${placeId}`, {
      //   dataUpdated: true,
      // });
    } else {
      alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
    }
  };

  const handleDeleteMainDestinationCompletely = async () => {
    await makeApiCall("DELETE", `/admin/main-destination/${placeId}`);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(`/admin`);
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        console.log("FAIL: ----- ", resultRef.current);
        // alert(`FAIL-xy: ${error.msg}`);
        // alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const updateBestTimeToGo = (event) => {
    event.preventDefault();
    if (!bestTimeToGoTitle || !bestTimeToGoInfo) {
      alert("Enter title or info");
      return;
    }

    let updatedBestTimeToGoList;
    if (bestTimeToGoId) {
      // not `null` means editing
      updatedBestTimeToGoList = bestTimeToGoList.map((bestTimeToGo) => {
        if (bestTimeToGo._id === bestTimeToGoId) {
          return {
            ...bestTimeToGo,
            title: bestTimeToGoTitle,
            info: bestTimeToGoInfo,
          };
        }
        return bestTimeToGo;
      });
    } else {
      const newItem = {
        title: bestTimeToGoTitle,
        info: bestTimeToGoInfo,
      };
      updatedBestTimeToGoList = [...bestTimeToGoList, newItem];
    }

    setBestTimeToGoList(updatedBestTimeToGoList);
    setBestTimeToGoId(null);
    setBestTimeToGoTitle("");
    setBestTimeToGoInfo("");
  };

  const bestTimeToGoItemClick = (item) => {
    setBestTimeToGoId(item._id);
    setBestTimeToGoTitle(item.title);
    setBestTimeToGoInfo(item.info);
  };

  const bestTimeToGoItemDelete = (item) => {
    // const item1 = bestTimeToGoInfo != "";
    // const item2 = bestTimeToGoInfo != "<p><br></p>";
    // const isEmptyString = bestTimeToGoInfo.trim() === ""; // Handles whitespace-only strings
    // const isEmptyParagraph = bestTimeToGoInfo.trim() === "<p><br></p>"; // Detects specific empty HTML structure

    /*  Test Cases Covered:
    "" →  Empty string.
    " " → Whitespace-only string.
    "<p><br></p>" → Single empty paragraph with a line break.
    "<p>&nbsp;</p>" → Single empty paragraph with a non-breaking space.
    "<p> </p>" → Single empty paragraph with spaces.
  */

    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      bestTimeToGoInfo.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (bestTimeToGoTitle || isValidContent) {
      //if value exist means editing or adding operation is going on
      alert(`please complete your edit or add operation first`);
      return;
    }

    // alert(`you can now delete`);
    let newList = bestTimeToGoList.filter((it) => it._id !== item._id);
    setBestTimeToGoList(newList);
  };

  const updateThingsToKnow = (event) => {
    event.preventDefault();
    if (!thingsToKnow) {
      alert("Enter info");
      return;
    }
    const cleanHtml = sanitizeHtml(thingsToKnow);
    let updatedThingsToKnowList;
    if (thingsToKnowId) {
      // not `null` means editing
      updatedThingsToKnowList = thingsToKnowList.map((ttk) => {
        if (ttk._id === thingsToKnowId) {
          return {
            ...ttk,
            // item: cleanHtml,
            item: thingsToKnow,
          };
        }
        return ttk;
      });
    } else {
      const newItem = {
        // item: cleanHtml,
        item: thingsToKnow,
      };
      updatedThingsToKnowList = [...thingsToKnowList, newItem];
    }

    setThingsToKnowList(updatedThingsToKnowList);
    setThingsToKnowId(null);
    setThingsToKnow("");
  };

  const thingsToKnowItemClick = (item) => {
    setThingsToKnowId(item._id);
    setThingsToKnow(item.item);
  };

  const thingsToKnowItemDelete = (item) => {
    if (thingsToKnow) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = thingsToKnowList.filter((it) => it._id !== item._id);
    setThingsToKnowList(newList);
  };

  const updateMoreInfo = (event) => {
    event.preventDefault();
    if (!moreInfoTitle || !moreInfoDescription) {
      alert("Enter title or description");
      return;
    }
    let updatedMoreInfos;
    if (moreInfoId) {
      // not `null` means editing
      updatedMoreInfos = moreInfos.map((moreInfo) => {
        if (moreInfo._id === moreInfoId) {
          return {
            ...moreInfo,
            title: moreInfoTitle,
            description: moreInfoDescription,
          };
        }
        return moreInfo;
      });
    } else {
      const newInfo = {
        title: moreInfoTitle,
        description: moreInfoDescription,
      };
      updatedMoreInfos = [...moreInfos, newInfo];
    }
    console.log("UPDATED ITEMS: ", updatedMoreInfos);
    setMoreInfos(updatedMoreInfos);
    setMoreInfoId(null);
    setMoreInfoTitle("");
    setMoreInfoDescription("");
  };

  const moreInfoItemClick = (id, title, description) => {
    setMoreInfoId(id);
    setMoreInfoTitle(title);
    setMoreInfoDescription(description);
  };

  const moreInfoItemDelete = (id) => {
    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      moreInfoDescription.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (moreInfoTitle || isValidContent) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = moreInfos.filter((it) => it._id !== id);
    setMoreInfos(newList);
  };

  const updateWatchInfo = (event) => {
    event.preventDefault();
    if (!videoTitle || !videoUrl) {
      alert("Enter video title or url");
      return;
    }

    var e = document.getElementById("language-select");
    var selectedLanguage = e.options[e.selectedIndex].text;
    let updatedWatchInfos;
    if (watchInfoId) {
      // not `null` means editing
      updatedWatchInfos = watchInfos.map((watchInfo) => {
        if (watchInfo._id === watchInfoId) {
          return {
            ...watchInfo,
            video_title: videoTitle,
            video_url: videoUrl,
            video_language: selectedLanguage,
          };
        }
        return watchInfo;
      });
    } else {
      const newInfo = {
        video_title: videoTitle,
        video_url: videoUrl,
        video_language: selectedLanguage,
      };
      updatedWatchInfos = [...watchInfos, newInfo];
    }

    setWatchInfos(updatedWatchInfos);
    setVideoTitle("");
    setVideoUrl("");
    setWatchInfoId(null);
  };

  const watchInfoItemClick = (watchInfo) => {
    setWatchInfoId(watchInfo._id);
    setVideoTitle(watchInfo.video_title);
    setVideoUrl(watchInfo.video_url);
  };

  const watchInfoItemDelete = (watchInfo) => {
    if (videoTitle || videoUrl) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = watchInfos.filter((it) => it._id !== watchInfo._id);
    setWatchInfos(newList);
  };

  const updateHowToReach = (event) => {
    event.preventDefault();
    if (!howToReachTitle || !howToReachInfo) {
      alert("Enter title or info");
      return;
    }

    let updatedHowToReachList;
    if (howToReachId) {
      // not `null` means editing
      updatedHowToReachList = howToReachList.map((howToReach) => {
        if (howToReach._id === howToReachId) {
          return {
            ...howToReach,
            title: howToReachTitle,
            info: howToReachInfo,
          };
        }
        return howToReach;
      });
    } else {
      const newItem = {
        title: howToReachTitle,
        info: howToReachInfo,
      };
      updatedHowToReachList = [...howToReachList, newItem];
    }

    setHowToReachList(updatedHowToReachList);
    setHowToReachId(null);
    setHowToReachTitle("");
    setHowToReachInfo("");
  };

  const howToReachItemClick = (item) => {
    setHowToReachId(item._id);
    setHowToReachTitle(item.title);
    setHowToReachInfo(item.info);
  };

  const howToReachItemDelete = (item) => {
    const isRichTextEmpty = /^(<p>(<br>|&nbsp;|\s)*<\/p>)*$/i.test(
      howToReachInfo.trim()
    );
    const isValidContent = !isRichTextEmpty;

    if (howToReachTitle || isValidContent) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = howToReachList.filter((it) => it._id !== item._id);
    setHowToReachList(newList);
  };

  const checkboxClickListener = () => {
    setMakePublish(!makePublish);
  };

  const editMainDestinationForm = () => {
    return (
      <div>
        <form class="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
          {/* checked={makePublish} */}
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              id="publish-checkbox"
              checked={makePublish}
              onClick={checkboxClickListener}
            />
            {`  make publish`}
          </label>

          <p className="uk-text-success ">Key Info(fields)</p>
          {/* <--  Name --> */}
          <div className="uk-margin">
            <label className="uk-form-label">Name</label>
            <input
              className="uk-input"
              type="text"
              placeholder="Destination name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 3,
                  message: "Place name must be at least 3 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>
          {/* <--  Overview --> */}
          <div className="uk-margin">
            <label class="uk-form-label">Overview (place info)</label>
            <RichTextEditor
              placeholder="Destination overview (only key-infos)..."
              value={placeOverview}
              onChange={setPlaceOverview}
            />
          </div>
          {/* <--  Best time to go--> */}
          <div className="uk-margin">
            <label class="uk-form-label">Best time to visit</label>
            {/* <p>{bestTimeToGoInfo}</p> */}
            <input
              class="uk-input"
              type="text"
              placeholder="Title of info [overview, peak season, off season, moderate season, etc]"
              value={bestTimeToGoTitle}
              onChange={(e) => setBestTimeToGoTitle(e.target.value)}
            />
            <RichTextEditor
              placeholder="info..."
              value={bestTimeToGoInfo}
              onChange={setBestTimeToGoInfo}
            />

            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateBestTimeToGo}
            >
              {bestTimeToGoId ? "update" : "add"}
            </button>
            <section>
              {bestTimeToGoList.map((item) => {
                return (
                  <div>
                    <div>
                      <span className="uk-text-bold uk-margin-small-right">
                        {item.title}
                      </span>
                      {item._id && (
                        <>
                          <FiEdit2
                            className="edit-icon uk-margin-small-right"
                            data-uk-tooltip="title: edit; pos: bottom-left"
                            onClick={() => bestTimeToGoItemClick(item)}
                          />
                          <FiTrash
                            className="delete-icon"
                            data-uk-tooltip="title: delete; pos: bottom-left"
                            onClick={() => bestTimeToGoItemDelete(item)}
                          />
                        </>
                      )}
                    </div>
                    <HTMLRenderer htmlContent={item.info} />

                    <hr />
                  </div>
                );
              })}
            </section>
          </div>
          {/* <-- Things to know --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Things to know or travel Tips (bullet list, optional)
            </label>
            <RichTextEditor
              placeholder="Things to be aware about the destination..."
              value={thingsToKnow}
              onChange={setThingsToKnow}
            />
          </div>
          {/* <div className="uk-margin">
            <label class="uk-form-label">
              Things to know (bullet list, optional)
            </label>
            <RichTextEditor
              placeholder="Things to be aware about the destination..."
              value={thingsToKnow}
              onChange={setThingsToKnow}
            />

            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateThingsToKnow}
            >
              {thingsToKnowId ? "update" : "add"}
            </button>

            <ul>
              {thingsToKnowList.map((ttk) => {
                return (
                  <li>
                    <HTMLRenderer htmlContent={ttk.item} />
                    {ttk._id && (
                      <>
                        <FiEdit2
                          className="edit-icon uk-margin-small-left uk-margin-small-right"
                          data-uk-tooltip="title: edit; pos: bottom-left"
                          onClick={() => thingsToKnowItemClick(ttk)}
                        />

                        <FiTrash
                          className="delete-icon"
                          data-uk-tooltip="title: delete; pos: bottom-left"
                          onClick={() => thingsToKnowItemDelete(ttk)}
                        />
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div> */}

          {/* --- */}
          {/* <-- only watch --> */}
          <div className="uk-margin">
            <label className="uk-form-label">
              Only Watch (infos, optional)
            </label>
            {/* <input
              className=""
              type="file"
              // onChange={handleOnChange}
            /> */}
            <label className="uk-form-label">Language</label>
            <select className="uk-select" id="language-select">
              <option>hindi</option>
              <option>english</option>
            </select>
            <input
              className="uk-input"
              type="text"
              placeholder="video title"
              onChange={(e) => setVideoTitle(e.target.value)}
              value={videoTitle}
            />
            <input
              className="uk-input"
              type="text"
              placeholder="video url"
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateWatchInfo}
            >
              {watchInfoId ? "update" : "add"}
            </button>

            <section className="uk-flex uk-margin-medium-top">
              {watchInfos.map((watchInfo) => {
                return (
                  <div>
                    <div>
                      <span className="uk-text-bold uk-margin-small-right">
                        {watchInfo.video_title}
                      </span>
                      {watchInfo._id && (
                        <>
                          <FiEdit2
                            className="edit-icon uk-margin-small-left uk-margin-small-right"
                            data-uk-tooltip="title: edit; pos: bottom-left"
                            onClick={() => watchInfoItemClick(watchInfo)}
                          />
                          <FiTrash
                            className="delete-icon"
                            data-uk-tooltip="title: delete; pos: bottom-left"
                            onClick={() => watchInfoItemDelete(watchInfo)}
                          />
                        </>
                      )}
                    </div>
                    <p className="uk-margin-remove-top">
                      {watchInfo.video_language}
                    </p>
                  </div>
                );
              })}
            </section>
          </div>
          {/*  */}
          {/* <-- More info about destination [list: title-description] --> */}
          <div className="uk-margin">
            {/* <p>{JSON.stringify(moreInfos)}</p> */}
            <label class="uk-form-label">
              More info about destination (title-description)
            </label>
            <input
              className="uk-input"
              type="text"
              placeholder="Title of info..."
              name="title"
              onChange={(e) => setMoreInfoTitle(e.target.value)}
              value={moreInfoTitle}
            />
            <RichTextEditor
              placeholder="Description of info..."
              value={moreInfoDescription}
              onChange={setMoreInfoDescription}
            />

            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateMoreInfo}
            >
              {moreInfoId ? "update info" : "add info"}
            </button>
            <section className="uk-margin-medium-top">
              {moreInfos.map((moreInfo) => {
                return (
                  <div>
                    <div>
                      <span className="uk-text-bold uk-margin-small-right">
                        {moreInfo.title}
                      </span>
                      {moreInfo._id && (
                        <>
                          <FiEdit2
                            className="edit-icon uk-margin-small-right"
                            data-uk-tooltip="title: edit; pos: bottom-left"
                            onClick={() =>
                              moreInfoItemClick(
                                moreInfo._id,
                                moreInfo.title,
                                moreInfo.description
                              )
                            }
                          />
                          <FiTrash
                            className="delete-icon"
                            data-uk-tooltip="title: delete; pos: bottom-left"
                            onClick={() => moreInfoItemDelete(moreInfo._id)}
                          />
                        </>
                      )}
                    </div>
                    <HTMLRenderer htmlContent={moreInfo.description} />

                    <hr />
                  </div>
                );
              })}
            </section>
          </div>
          {/* <--  How to reach --> */}
          <div className="uk-margin">
            <label class="uk-form-label">How to reach</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Title of info [overview, flight, train, road]"
              value={howToReachTitle}
              onChange={(e) => setHowToReachTitle(e.target.value)}
            />
            <RichTextEditor
              placeholder="info..."
              value={howToReachInfo}
              onChange={setHowToReachInfo}
            />

            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateHowToReach}
            >
              {howToReachId ? "update" : "add"}
            </button>
            <section>
              {howToReachList.map((item) => {
                return (
                  <div>
                    <div>
                      <span className="uk-text-bold uk-margin-small-right">
                        {item.title}
                      </span>
                      {item._id && (
                        <>
                          <FiEdit2
                            className="edit-icon uk-margin-small-left uk-margin-small-right"
                            data-uk-tooltip="title: edit; pos: bottom-left"
                            onClick={() => howToReachItemClick(item)}
                          />
                          <FiTrash
                            className="delete-icon"
                            data-uk-tooltip="title: delete; pos: bottom-left"
                            onClick={() => howToReachItemDelete(item)}
                          />
                        </>
                      )}
                    </div>
                    <HTMLRenderer htmlContent={item.info} />

                    <hr />
                  </div>
                );
              })}
            </section>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                // name="countryRadBtn"
                value="yes"
                {...register("countryRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                // name="countryRadBtn"
                value="no"
                {...register("countryRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.countryRadBtn?.message}
            </span>
          </div>
          {/* <-- popular in state --> */}
          <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in state</label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                // name="stateRadBtn"
                value="yes"
                {...register("stateRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                // name="stateRadBtn"
                value="no"
                {...register("stateRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.stateRadBtn?.message}
            </span>
          </div>
          {/* <-- Theme type --> */}
          <div className="uk-margin">
            <label className="uk-form-label">Theme Type</label>
            <select
              className="uk-select"
              id="theme-select"
              {...register("themeType", { required: true })}
            >
              {themes.map((theme) => {
                return <option>{theme}</option>;
              })}
            </select>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div className="uk-margin">
            <label class="uk-form-label">
              Place Type ((FIXED - maindestination))
            </label>
            <select
              class="uk-select"
              id="place-type-select"
              {...register("placeType", { required: true })}
              disabled
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>

          {/* <-- destination type --> */}
          <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">
              Destination type (FIXED - maindestination)
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="destRadBtn"
                value="mainDestination"
                checked
              />
              main destination
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="destRadBtn"
                value="finalDestination"
                disabled
              />
              final destination
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="destRadBtn"
                value="none"
                disabled
              />
              none
            </label>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>
          <div className="uk-margin">
            <input
              className="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setAddress(e.target.value)}
              // value={address}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>
          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                className="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                // onChange={(e) => setLatLong(e.target.value)}
                // value={latLong}
                {...register("latLong")}
              />
            </div>
            <div>
              <input
                className="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                // onChange={(e) => setCountry(e.target.value)}
                // value={country}
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>
            <div>
              <input
                className="uk-input"
                type="text"
                placeholder="state"
                // onChange={(e) => setState(e.target.value)}
                // value={state}
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          {/* <-- source references  --> */}
          <p className="uk-text-success uk-margin-medium-top">Others info</p>
          <div class="uk-margin">
            <label class="uk-form-label">
              Source references (bullet list, optional)
            </label>
            <RichTextEditor
              placeholder="resources links in bullet list format..."
              value={sourceReferences}
              onChange={setSourceReferences}
            />
            {/* <textarea
              class="uk-textarea"
              rows="5"
              placeholder="links separated with ##"
              {...register("sourceReferences")}
            ></textarea> */}
          </div>
          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            type="submit"
            // onClick={handleSubmit}
          >
            update
          </button>
        </form>

        {/* <--  Delete --> */}
        <button
          className="uk-button uk-button-danger uk-margin-small-bottom"
          onClick={handleDeleteMainDestinationCompletely}
          // disabled={true}
        >
          delete
        </button>
        {showLoading()}
        {errorMessage()}
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const editFormSegemnt = () => {
    // let component;
    // if (placeType === "maindestination") component = editMainDestinationForm;
    // else if (placeType === "finaldestination")
    //   component = (
    //     <EditSubDestination destinationId={name} placeId={editingPlaceId} />
    //   );
    // else component = <EditNeighbour />;
    // return component;
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h2 className="uk-margin-small-top">Update {placeType} Info </h2>
      {/* {editFormSegemnt()} */}
      {placeType === MAINDESTINATION && editMainDestinationForm()}
      {/* {placeType === FINALDESTINATION && (
        <EditSubDestinationRichText placeId={placeId} />
      )} */}
      {placeType === NEIGHBOUR && <EditNeighbour placeId={placeId} />}

      {/* {redirect && (
        <Redirect
          to={`/admin/destination-listing/${placeId}?refreshRequired=true`}
        />
      )} */}
    </Wrapper>
  );
}

export default EditDestinationRichText;

const Wrapper = styled.main`
  background: #f2f2f2;
  margin-top: 2rem;

  .edit-icon {
    /* grey icon */
    color: #666;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .edit-icon:hover {
    color: #575656;
  }
  .delete-icon {
    color: #ff0000;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .delete-icon:hover {
    color: #f30909;
  }
`;
