import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiBookmark } from "react-icons/fi";
import { FaRegBookmark, FaBookmark, FaHeart } from "react-icons/fa";
import { FiThumbsUp, FiEdit2, FiTrash, FiX } from "react-icons/fi";
import { Helmet } from "react-helmet";

import mockData from "./../mockDb";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import { getUser, imageNotFound, mandatoryField } from "../utils/utility";
import CustomModal from "../components/CustomModal";
import useModal from "../utils/custom-hooks/useModal";

function SingleBucketPage() {
  const { bucketId } = useParams();
  const user = getUser();
  const history = useHistory();

  const [bucket, setBucket] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const { isShow, toggleModal } = useModal();

  const [destinations, setDestinations] = useState();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchBucket();
  }, []);

  const fetchBucket = async () => {
    await makeApiCall("GET", `/bucket/${bucketId}/${user.user_id}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        //  setBucketList(resultRef.current.buckets);
        setBucket(resultRef.current.bucket);
        setDestinations(resultRef.current.bucket.places);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const removeItemFromBucket = async (placeId) => {
    const data = {
      bucket_id: bucketId,
      place_id: placeId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/remove-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        toast.success(`${resultRef.current.message}`);
        let data = destinations.filter((dest) => dest.place_id !== placeId);
        setDestinations(data);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const updateBucket = async (data) => {
    const req = {
      bucket_name: data.bucketName,
      bucket_description: data.bucketDescription,
      user_id: user.user_id,
      bucket_id: bucketId,
    };
    await makeApiCall("PUT", `/bucket/edit`, req);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success("hey... bucket updated successfully");
        setBucket(resultRef.current.bucket);
        setShowEditForm(false);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const deleteBucket = async () => {
    toggleModal();
    await makeApiCall("DELETE", `/bucket/delete/${bucketId}/${user.user_id}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        toast.success("bucket deleted successfully");
        history.replace("/my-bucket");
        // history.goBack('');
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const editButtonClickHandler = () => {
    setShowEditForm(!showEditForm);
    setValue("bucketName", bucket.bucket_name);
    setValue("bucketDescription", bucket.bucket_description);
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const editBucketForm = () => {
    return (
      <>
        <form class="uk-form-stacked" onSubmit={handleSubmit(updateBucket)}>
          {/* {showLoading()} */}
          {/* {errorMessage()} */}
          <h4 class="uk-margin-small-top">Editing your bucket</h4>
          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="Name of your bucket"
              {...register("bucketName", mandatoryField)}
            />
          </div>
          <span className="uk-text-danger">{errors.bucketName?.message}</span>
          <div class="uk-margin">
            <textarea
              class="uk-textarea"
              rows="5"
              placeholder="Description (optional)"
              {...register("bucketDescription")}
            />
            <span className="uk-text-danger">
              {errors.bucketDescription?.message}
            </span>
          </div>
          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={submitForm}
            type="submit"
          >
            update
          </button>
        </form>
      </>
    );
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {bucket && bucket.bucket_name && (
        <>
          <Helmet>
            <title>{bucket.bucket_name} - travelBucket </title>
          </Helmet>
          <div className="uk-flex uk-flex-row uk-flex-right">
            {showEditForm ? (
              <FiX
                className="edit-icon uk-margin-small-right"
                data-uk-tooltip="title: edit bucket; pos: bottom-left"
                onClick={() => editButtonClickHandler()}
              />
            ) : (
              <FiEdit2
                className="edit-icon uk-margin-small-right"
                data-uk-tooltip="title: edit bucket; pos: bottom-left"
                onClick={() => editButtonClickHandler()}
              />
            )}

            <FiTrash
              className="delete-icon"
              data-uk-tooltip="title: delete bucket; pos: bottom-left"
              onClick={() => toggleModal()}
            ></FiTrash>
          </div>
          <h3 className="uk-margin-remove-top">
            List of all places inside your bucket
            <span className="bucket-name">{bucket.bucket_name}</span>
          </h3>
        </>
      )}

      {/* create crashes & sends to sentry but not providing line number or wrong line number */}
      {/* <h3 className="uk-margin-remove-top">
        List of all places inside your bucket
        <span className="bucket-name">{bucket.bucket_name}</span>
      </h3> */}

      {showLoading()}
      {errorMessage()}

      {showEditForm && editBucketForm()}

      <CustomModal modalType={"alert"} isShow={isShow} closeModal={toggleModal}>
        <>
          <p>Do you really want to delete your bucket?</p>
          <button
            className="uk-button uk-button-danger uk-button-smal uk-margin-small-right"
            onClick={() => deleteBucket()}
          >
            Yes
          </button>

          <button
            className="uk-button uk-button-default uk-button-smal"
            onClick={() => toggleModal()}
          >
            No
          </button>
        </>
      </CustomModal>

      {destinations && destinations.length === 0 && (
        <>
          <div class="uk-alert-warning" data-uk-alert>
            <p className="uk-text-warning">
              You have not added any place in this bucket. Browse & add places,
              you want to go in your upcoming trips
            </p>
            <Link to="/" className="uk-button uk-button-secondary">
              Browse Places
            </Link>
          </div>
        </>
      )}

      <section
        className="uk-margin-medium-top"
        data-uk-scrollspy="cls: uk-animation-fade; target: .change-direction; delay:200"
      >
        {destinations &&
          destinations.map((dest) => {
            const redirect =
              dest.place_type === "finaldestination"
                ? `/final-destination/${dest.place_id}`
                : `/main-destination/${dest.place_id}`;

            return (
              <article>
                <div className="uk-flex uk-flex-row uk-flex-between change-direction">
                  <div className="uk-width-1-3@s img-container">
                    <img
                      src={dest.images[0] ? dest.images[0].image_url : ""}
                      alt={dest.place_name}
                      onError={(e) => {
                        if (e.target.src !== imageNotFound) {
                          e.target.src = imageNotFound;
                        }
                      }}
                    />
                    <div className="bookmark-container">
                      <FaHeart
                        className="bookmark-icon"
                        onClick={() => removeItemFromBucket(dest.place_id)}
                      />
                    </div>
                  </div>

                  <div className="uk-width-2-3@s text-container">
                    <Link to={redirect} className="remove-underline">
                      <h4 className="place-name">{dest.place_name}</h4>
                    </Link>
                    <p className="uk-margin-remove-top">
                      {dest.place_info_short_description}
                    </p>
                    {/* <FaBookmark
                    className="bookmark-icon"
                    onClick={() => removeItemFromBucket(dest.place_id)}
                  ></FaBookmark> */}
                  </div>
                </div>

                <div className="border uk-margin-small-top uk-margin-medium-bottom"></div>
              </article>
            );
          })}
      </section>
    </Wrapper>
  );
}

export default SingleBucketPage;
const Wrapper = styled.main`
  .bucket-name {
    color: #338280;
    /* color: var(--color-primary); */
    margin-left: 0.5rem;
  }

  .place-img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    /* background-size: cover; */
    background-color: "#ebe4e4";
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  .border {
    background-color: #c5c5c5;
    height: 1px;
    width: 100%;
  }

  .remove-underline {
    text-decoration: none;
    background-color: #c5c5c5;
  }
  .img-container {
    position: relative;
    background: var(--imageBackgroundColor);
  }
  .img-container img {
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
  }
  .bookmark-container {
    position: absolute;
    /* width: 2rem; */
    /* height: 2rem; */
    padding: 0.45rem;
    background: #fff;
    right: 1rem;
    bottom: 1rem;
    z-index: 5;
    /* border-radius: 50%; */
  }
  .text-container {
    margin-left: 10px;
  }
  .place-name {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 576px) {
    .text-container {
      margin-left: 0;
    }
    .change-direction {
      flex-direction: column;
    }
    .mt {
      margin-top: 1rem;
    }
    .place-name {
      margin: 20px 0 10px;
    }
  }

  .edit-icon {
    /* grey icon */
    color: #666;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .edit-icon:hover {
    color: #575656;
  }
  .delete-icon {
    color: #ff0000;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  .delete-icon:hover {
    color: #f30909;
  }
`;
