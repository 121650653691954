import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";

import useApi from "../../utils/custom-hooks/useApi";
import Loading from "../../components/Loading";
import { useAppContext } from "../../contexts/appContext";

function VideoPage() {
  const { actionType, videoId } = useParams();

  const [isChecked, setIsChecked] = useState(false);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [videoLanguage, setVideoLanguage] = useState("none");
  const history = useHistory();
  const { addInCache, getFromCache } = useAppContext();

  useEffect(() => {
    if (actionType === "edit") {
      const data = getFromCache(`video-id-${videoId}`);
      if (!data) {
        fetchVideo();
      } else {
        setAllData(data);
      }
    }
  }, []);

  const fetchVideo = async () => {
    await makeApiCall("GET", `/video/${videoId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setAllData(resultRef.current);
        addInCache(`video-id-${videoId}`, resultRef.current);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const setAllData = (response) => {
    setTitle(response.video.title);
    setUrl(response.video.url);
    setIsChecked(response.video.at_home_page);
    setVideoLanguage(response.video.language);
  };

  const submitForm = () => {
    if (actionType === "edit") {
      updateVideo();
    } else {
      addVideo();
    }
  };

  const addVideo = async () => {
    if (!title || !url) {
      toast.error("Title or Url is empty");
      return;
    }

    if (videoLanguage === "none") {
      toast.error("Choose the video language");
      return;
    }

    const data = {
      title: title,
      url: url,
      // url: "https://www.youtube.com/watch?v=NLQqHYWi2OQ",
      language: videoLanguage,
      at_home_page: isChecked,
      image: "",
    };

    await makeApiCall("POST", `/admin/video`, data);

    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        const newlyUploadedVideoId = resultRef.current.video.video_id;
        addInCache(`video-id-${newlyUploadedVideoId}`, resultRef.current);
        //  history.replace(`/article/${newlyCreatedArticleId}`);
        // history.replace(`/watch`);
        history.replace(`/admin/video-listing`);
        console.error("RESPONSE: --- ", resultRef.current);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const updateVideo = async () => {
    if (!title || !url) {
      toast.error("Title or Url is empty");
      return;
    }

    if (videoLanguage === "none") {
      toast.error("Choose the video language");
      return;
    }

    const data = {
      video_id: videoId,
      title: title,
      url: url,
      language: videoLanguage,
      at_home_page: isChecked,
      image: "",
    };

    await makeApiCall("PUT", `/admin/video`, data);

    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        const newlyUploadedVideoId = resultRef.current.video.video_id;
        addInCache(`video-id-${newlyUploadedVideoId}`, resultRef.current);
        //  history.replace(`/article/${newlyCreatedArticleId}`);
        // history.replace(`/watch`);
        history.replace(`/admin/video-listing`);
        console.error("RESPONSE: --- ", resultRef.current);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const deleteVideo = async () => {
    const data = {
      video_id: videoId,
      title: title,
      url: url,
      language: videoLanguage,
      at_home_page: isChecked,
      image: "",
    };

    await makeApiCall("DELETE", `/admin/video/${videoId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        // history.replace(`/admin/articles?refreshRequired=true`);
        history.replace(`/admin/video-listing`);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const selectChangeListener = (e) => {
    const selectedLanguage = e.target.value;
    setVideoLanguage(selectedLanguage);
    //
    // fetchDestinationVideos(selectedLanguage);
    // console.log("WATCH --------------------- ", t);
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const videoForm = () => {
    return (
      <>
        {/* title */}
        <div class="uk-margin">
          <label class="uk-form-label">Video Title</label>
          <input
            class="uk-input uk-margin-small-bottom"
            type="text"
            placeholder="Name of your video..."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        {/* url */}
        <div class="uk-margin">
          <label class="uk-form-label">
            Video Url (https://www.youtube.com/embed/youtube_video_id)
          </label>
          <input
            class="uk-input uk-margin-small-bottom"
            type="text"
            placeholder="Video url link... https://www.youtube.com/embed/youtube_video_id"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>

        {/* checkbox */}
        <div class="uk-margin">
          <label className="tb">
            <input
              className="uk-checkbox"
              type="checkbox"
              id="at-home-page-checkbox"
              checked={isChecked}
              onClick={() => setIsChecked(!isChecked)}
            />
            {`  At Home Page`}
          </label>
        </div>

        {/* language */}
        <div className="uk-margin">
          <label for="items">Preferred language: </label>
          <select
            className="uk-select"
            name="items"
            id="items"
            onChange={selectChangeListener}
            // defaultValue={videoLanguage}
            value={videoLanguage}
          >
            <option value="none">select language</option>
            <option value="english">English</option>
            <option value="hindi">Hindi</option>
          </select>
        </div>

        <div className="uk-margin-small-bottom">
          <button
            className="uk-button uk-button-secondary uk-margin-medium-right"
            onClick={submitForm}
          >
            {actionType === "edit" ? "update" : "upload"}
          </button>

          {actionType === "edit" && (
            <button
              className="uk-button uk-button-danger"
              onClick={deleteVideo}
            >
              delete
            </button>
          )}
        </div>
      </>
    );
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Admin Video Page - travelBucket </title>
      </Helmet>
      {showLoading()}
      {errorMessage()}
      {/* <h3>{actionType === "edit" ? "Update" : "Upload"} Video Info</h3> */}
      <h2 className="uk-margin-small-top">
        {actionType === "edit" ? "Update" : "Upload"} Video Info
      </h2>

      {videoForm()}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #f2f2f2;
`;
export default VideoPage;
